<!-- VUETIFY2 - OK -->
<template>
    <base-layout datatableExcludedSizeDefault="210px" headerSize="50px">
        <template slot="header">
            <h1>Mouvements de parts sociales</h1>
        </template>
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-datatable-mouvements :readOnly="false"
                                    :mouvementsList="mouvements"
                                    :lastMouvementsIds="lastMouvementsIds"
                                    :canImportNewMvt="true"
                                    :canExportMvt="true"
                                    :height="datatableSize"
                                    :selectedExerciceId="selectedExercice"
                                    :selectedAssocieId="selectedAssocieId"
                                    :selectedMouvementType="typeFilter"
                                    :total_items="total_items"
                                    :showAssocieSelection="true"
                                    :showTypeSelection="true"
                                    :isDataLoading="isDataLoading"
                                    @delete-mouvement="function(mvtToDelete, currentPagination) {onDeleteMouvement(mvtToDelete, currentPagination)}"
                                    @delete-annulation-totale="function(mvtAssocieToDelete, currentPagination) {onDeleteAnnulationTotale(mvtAssocieToDelete, currentPagination)}"
                                    @update-selected-exercice="function(newfiltreExe, currentPagination) {filtreMouvements(newfiltreExe, typeFilter, selectedAssocieId, currentPagination)}"
                                    @update-mouvement-type-filter="function(newTypeFiltre, currentPagination) {filtreMouvements(selectedExercice, newTypeFiltre, selectedAssocieId, currentPagination)}"
                                    @set-headers-value="setHeadersValue($event)"
                                    @refresh-mvts-list="filtreMouvements(selectedExercice, typeFilter, selectedAssocieId, $event)"
                                    @update-associe-filter="function(newSelectedAssocie, currentPagination) {filtreMouvements(selectedExercice, typeFilter, newSelectedAssocie, currentPagination)}"
            />
        </template>
        <template slot="footer">
            <v-row dense>
                <!-- Petit bouton -->
                <v-col md="2" lg="2" xl="2">
                    <v-btn class="mb-0" dark color="primary" outlined @click="downloadExcel" block>
                        <v-icon :small="$vuetify.breakpoint.lgAndDown" dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Grand bouton-->
                <v-col md="3" lg="3" xl="3">
                    <v-btn class="mb-0" block dark color="primary" :to="{name: 'creationSouscription'}">
                        <v-icon :small="$vuetify.breakpoint.lgAndDown" left>{{ "$vuetify.icons.souscription" }}</v-icon>
                        Créer une souscription
                    </v-btn>
                </v-col>
                <!-- Grand bouton-->
                <v-col md="3" lg="3" xl="3">
                    <v-btn class="mb-0" block dark color="primary" :to="{name: 'creationAnnulation'}">
                        <v-icon :small="$vuetify.breakpoint.lgAndDown" left>{{ "$vuetify.icons.annulation" }}</v-icon>
                        Créer une annulation partielle
                    </v-btn>
                </v-col>
                <!-- Grand bouton-->
                <v-col md="3" lg="3" xl="3">
                    <v-btn block dark color="primary" :to="{name: 'annulationtotale'}">
                        <v-icon :small="$vuetify.breakpoint.lgAndDown" left>{{ "$vuetify.icons.annulationTotale" }}</v-icon>
                        Créer une annulation totale
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import VDatatableMouvements from "@/components/mouvements/VDatatableMouvements.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import Utils from "@/utils";

    /**
     * Vue affichant la liste des mouvements de parts sociales réalisés par associé et par
     * exercice
     * @displayName Capital - MouvementList
     */
    export default {
        components: {
            BaseLayout,
            loadingScreen,
            VDatatableMouvements
        },
        mounted() {
            const filtres_actuels = this.$store.getters.getFiltresMouvements();
            if (!_.isNil(filtres_actuels)) {
                if (filtres_actuels.exercice_filtre) {
                    this.selectedExercice = filtres_actuels.exercice_filtre;
                }
                if (filtres_actuels.associe_id_filter) {
                    this.selectedAssocieId = filtres_actuels.associe_id_filter;
                }
                if (filtres_actuels.mouvement_type_filter) {
                    this.typeFilter = filtres_actuels.mouvement_type_filter;
                }
                internalApi.mouvement.getLabels().then(result => {
                    this.labels = result["type_mouvement"];
                    this.labels_sous_types = result["sous_type_mouvement"];
                });
            }
        },
        data() {
            return {
                labels: {},
                labels_sous_types: {},
                selectedExercice: -1,
                selectedAssocieId: -1,
                mouvements: [],
                lastMouvementsIds: [],
                headers: [],
                typeFilter: "all",
                total_items: -1,
                isDataLoading: true,
            };
        },
        methods: {
            /**
             * Supprime un mouvement par via son identifiant, puis met à jour la
             * datatable
             * @param id l'identifiant du mouvement à supprimer
             * @param currentPagination Les informations conernant l'affichage des données
             * dans la datatable
             */
            onDeleteMouvement(id, currentPagination) {
                this.$refs.loadingComponent.setLoading("Suppression du mouvement en cours");
                internalApi.mouvement.deleteMouvementById(id).then(result => {
                    // on rappelle le back pour maj les infos
                    this.filtreMouvements(this.selectedExercice, this.typeFilter, this.selectedAssocieId, currentPagination);
                }).catch(error => {
                    console.error(error);
                    this.$refs.loadingComponent.resetLoading();
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            onDeleteAnnulationTotale(annulation, currentPagination) {
                this.$refs.loadingComponent.setLoading("Suppression d'une annulation totale");
                let associe_id = annulation.associe_id;
                let date_mouvement = annulation.date_mouvement;
                internalApi.annulationtotale.deleteAnnulationTotale(associe_id, date_mouvement).then(result => {
                    this.filtreMouvements(this.selectedExercice, this.typeFilter, this.selectedAssocieId, currentPagination);
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                });
            },
            /**
             * Retourne vrai si le nombre de parts sociales du mouvement est différent de 0
             * @param mouvement le mouvement à vérifier
             * @returns {boolean}
             */
            checkNbPartsSociales(mouvement) {
                return !(mouvement['nb_parts_sociales'] === 0);
            }, 
            downloadExcel() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement)
                internalApi.mouvement.downloadTotaliteMouvementsExcel()
                    .then(response => {
                        Utils.downloadFile(response, "mouvements_parts_sociales.xlsx", Utils.excelMimetype);
                    })
                    .finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    })
            },
            /**
             * Met à jour le store avec les informations de filtre des mouvements
             * de parts sociales
             * @param exerciceId filtre de l'identifiant d'exercice
             * @param mouvementTypeFilter filtre du type de mouvement
             * @param newAssocieId filtre de l'identifiant de l'associé
             */
            saveFiltre(exerciceId, mouvementTypeFilter, newAssocieId) {
                this.selectedExercice = exerciceId;
                this.typeFilter = mouvementTypeFilter;
                this.selectedAssocieId = newAssocieId;

                this.$store.commit("SET_FILTRES_MOUVEMENTS", {
                    exercice_filtre: exerciceId,
                    mouvement_type_filter: mouvementTypeFilter,
                    associe_id_filter: newAssocieId
                });
            },
            /**
             * Récupère les mouvements de parts sociales filtrés par les paramètres
             * @param newSelectedExerciceId filtre par identifiant d'exercice
             * @param mouvementTypeFilter filtre par type de mouvement
             * @param associeIdFilter filtre par l'identifiant de l'associé
             * @param currentPagination informations conernant la mise en forme du résultat
             */
            filtreMouvements(newSelectedExerciceId, mouvementTypeFilter, associeIdFilter, currentPagination) {
                this.saveFiltre(newSelectedExerciceId, mouvementTypeFilter, associeIdFilter);
                this.isDataLoading = true;
                internalApi.mouvement.getAll({
                    page: currentPagination.page,
                    rows_per_page: currentPagination.itemsPerPage,
                    sort_key: currentPagination.sortBy[0],
                    descending: currentPagination.sortDesc[0],
                    exercice_filter: this.selectedExercice,
                    mouvement_type_filter: this.typeFilter,
                    associe_id_filter: this.selectedAssocieId,
                }).then(result => {
                    this.mouvements = result.mouvements.filter(this.checkNbPartsSociales);
                    this.lastMouvementsIds = result.last_mouvements_ids;
                    this.total_items = result.total_items;
                }).catch(err => {
                    console.log(err);
                }).finally( () => {
                    this.isDataLoading = false;
                });
            },
            setHeadersValue(headersValue) {
                this.headers = headersValue;
            }
        }
    };
</script>
