<!-- VUETIFY2 - OK -->
<template>
    <div>
        <fieldset>
            <legend>Identification</legend>
            <v-row align="center">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localCivilite" name="Civilité"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-select v-model="localCivilite" label="Civilité"
                                  :items="selectItems.civilite"
                                  :error-messages="errors"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localFirstName" name="Prénom"
                                        rules="required|max:100"
                                        v-slot="{ errors }">
                        <v-text-field v-model.trim="localFirstName" label="Prénom"
                                      :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localLastName" name="Nom"
                                        rules="required|max:100"
                                        v-slot="{ errors }">
                        <v-text-field v-model.trim="localLastName" label="Nom"
                                      :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localNumeroTiers" name="Numéro de tiers"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-text-field v-model="localNumeroTiers" label="Numéro de tiers"
                                      :error-messages="errors" :disabled="isEspacePersonnel"/>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </fieldset>
        <fieldset>
            <legend>Naissance</legend>
            <v-row align="center">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localDateNaissance" name="Date de naissance"
                                        rules="required"
                                        v-slot="{ errors }">
                        <date-field v-model="localDateNaissance"
                                    label="Date de naissance"
                                    :errors="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localCodePostalNaissance" name="Code postal de naissance"
                                        :rules="{ max: 20, required: this.infos_ville_naissance_required }"
                                        v-slot="{ errors }">
                        <v-text-field v-model.trim="localCodePostalNaissance"
                                      label="Code postal de naissance"
                                      :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localCommuneNaissance" name="Commune de naissance"
                                        :rules="{ regex: /^([\xC0-\xFFa-zA-Z- '()]+)$/, max: 80, required: this.infos_ville_naissance_required }"
                                        v-slot="{ errors }">
                        <v-text-field v-model.trim="localCommuneNaissance"
                                      label="Commune de naissance"
                                      :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localPaysNaissance"
                                        name="Pays de naissance"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-countries-list v-model="localPaysNaissance"
                                          label="Pays de naissance"
                                          :errors="errors"
                        />
                    </ValidationProvider>
                </v-col>
            </v-row>
        </fieldset>
        <fieldset>
            <legend>Contact</legend>
            <v-row align="center">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localEmail" name="Email"
                                        rules="email"
                                        v-slot="{ errors }">
                        <v-text-field v-model.trim="localEmail" label="Email"
                                      :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <ValidationProvider vid="localAcceptEnvoiDemat" name="Accepte les envois dématérialisés"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-checkbox v-model="localAcceptEnvoiDemat"
                                    label="Accepte les envois dématérialisés" false-value="false"
                                    :error-messages="errors"/>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </fieldset>
        <fieldset v-if="!isEspacePersonnel">
            <legend>Spécificités fiscales</legend>
            <v-row align="center">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <v-row align="center" no-gutters>
                        <v-col cols="11">
                            <v-checkbox v-model="localRenonciationPf"
                                        label="Renonciation au prélèvement forfaitaire"
                                        hide-details
                                        false-value="false"/>
                        </v-col>
                        <v-col cols="1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon small left v-on="{...on}">{{"$vuetify.icons.info"}}</v-icon>
                                </template>
                                <span v-html="messageRenonciationPrelevementForfaitaire"/>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <v-row align="center" no-gutters>
                        <v-col cols="11">
                            <v-checkbox v-model="localExonerationCs"
                                        label="Exonération des charges sociales"
                                        hide-details
                                        false-value="false"/>
                        </v-col>
                        <v-col cols="1">
                            <v-tooltip top class="ml-n4">
                                <template v-slot:activator="{ on }">
                                    <v-icon small left v-on="{...on}">{{"$vuetify.icons.info"}}</v-icon>
                                </template>
                                <span v-html="messageExonerationsChargesSociales"/>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                    <v-row align="center" no-gutters>
                        <v-col md="11">
                            <v-checkbox v-model="localExcluDocumentsLegaux"
                                        label="Exclusion de l’édition des documents légaux"
                                        hide-details
                                        false-value="false"/>
                        </v-col>
                        <v-col cols="1">
                            <v-tooltip top class="ml-n4">
                                <template v-slot:activator="{ on }">
                                    <v-icon small left v-on="{...on}">{{"$vuetify.icons.info"}}</v-icon>
                                </template>
                                <span v-html="messageExcluDocumentsLegaux"/>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </fieldset>
    </div>
</template>
<script>
    import internalApi from "@/api/internalApi";
    import DateField from "@/components/DateField.vue";
    import Utils from "@/utils";
    import VCountriesList from "@/components/VCountriesList";

    /**
     * Affiche un formulaire concernant les données personnelles d'une personne physique
     * @displayName Commun - Données personnelles
     */
    export default {
        components: {
            DateField,
            VCountriesList
        },
        name: "VDonneesPersonnelles",
        props: {
            /**
             * Civilité personne physique
             */
            civilite: {
                type: String,
                default: null
            },
            /**
             * Prénom personne physique
             */
            first_name: {
                type: String,
                default: null
            },
            /**
             * Nom personne physique
             */
            last_name: {
                type: String,
                default: null
            },
            /**
             * Numéro de tiers personne physique (facultatif)
             */
            numero_tiers: {
                type: String,
                default: null
            },
            /**
             * Date de naissance personne physique
             */
            date_naissance: {
                type: [Date, String],
                default: null
            },
            /**
             * Commune de naissance personne physique
             */
            commune_naissance: {
                type: String,
                default: null
            },
            /**
             * Code postal ville de naissance personne physique
             */
            code_postal_naissance: {
                type: String,
                default: null
            },
            /**
             * Pays de naissance personne physique
             */
            pays_naissance: {
                type: String
            },
            /**
             * Email personne physique (facultatif)
             */
            email: {
                type: String,
                default: null
            },
            /**
             * Si la personne physique accepte l'envoie dématérialisé
             */
            accept_envoi_demat: {
                type: Boolean,
                default: null
            },
            /**
             * Si on est sur l'espace personnel
             */
            isEspacePersonnel: {
                type: Boolean,
                default: true
            },
            /**
             * Si les informations relatives à la ville de naissance sont requises ou optionnelles
             */
            infos_ville_naissance_required: {
                type: Boolean,
                required: true
            },
            /**
             * Détermine si l'associé renonce au prélèvement forfaitaire ou non
             */
            renonciation_pf: {
                type: Boolean,
                default: null
            },
            /**
             * Détermine si l'associé est exonéré de charges sociales ou non
             */
            exoneration_cs: {
                type: Boolean,
                default: null
            },
            /**
             * Détermine si la personne est exclue de la génération des documents légaux
             */
            is_exclu_documents_legaux: {
                type: Boolean,
                default: null
            },
        },
        mounted() {
            this.getSelectItems();
        },
        data() {
            return {
                selectItems: {},
                messageRenonciationPrelevementForfaitaire: "Les associé(e)s qui renoncent au prélèvement forfaitaire sont exclu(e)s de ce prélèvement. Ils/Elles perçoivent donc le montant net des intérêts aux parts sociales qui leur est attribué.",
                messageExonerationsChargesSociales: "Les associé(e)s exonéré(e)s de charges sociales ne sont pas soumis(es) aux prélèvements sociaux (ex.: expatrié(e)s et travailleurs(euses) étrangers(ères) non soumis(es) aux charges sociales)",
                messageExcluDocumentsLegaux: "Les tiers qui sont exclus de l’édition des documents fiscaux sont ceux pour lesquels les déclarations 2561 et 2561 ter doivent être complétées (ex : jetons de présence).",
            }
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ a été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            /**
             * Récupère la liste des civilités possibles (Mr, Mme)
             * @returns {[string]}
             */
            getSelectItems() {
                return internalApi.personnePhysique.getLabels().then(result => {
                    let {personne_physique} = result;
                    this.selectItems = {
                        civilite: Utils.fromLabelToSelect(personne_physique.civilite),
                    };
                });
            },
        },
        computed: {
            localCivilite: {
                get: function () {
                    return this.civilite;
                },
                set: function (newvalue) {
                    this.$emit("update-civilite", newvalue);
                }
            },
            localFirstName: {
                get: function () {
                    return this.first_name;
                },
                set: function (newvalue) {
                    this.$emit("update-first-name", newvalue);
                }
            },
            localLastName: {
                get: function () {
                    return this.last_name;
                },
                set: function (newvalue) {
                    this.$emit("update-last-name", newvalue);
                }
            },
            localNumeroTiers: {
                get: function () {
                    return this.numero_tiers;
                },
                set: function (newvalue) {
                    this.$emit("update-numero-tiers", newvalue);
                }
            },
            localDateNaissance: {
                get: function () {
                    return this.date_naissance;
                },
                set: function (newvalue) {
                    this.$emit("update-date-naissance", newvalue);
                }
            },
            localCommuneNaissance: {
                get: function () {
                    return this.commune_naissance;
                },
                set: function (newvalue) {
                    this.$emit("update-commune-naissance", newvalue);
                }
            },
            localCodePostalNaissance: {
                get: function () {
                    return this.code_postal_naissance;
                },
                set: function (newvalue) {
                    this.$emit("update-code-postal-naissance", newvalue);
                }
            },
            localPaysNaissance: {
                get: function () {
                    return this.pays_naissance;
                },
                set: function (newvalue) {
                    this.$emit("update-pays-naissance", newvalue);
                }
            },
            localEmail: {
                get: function () {
                    return this.email;
                },
                set: function (newvalue) {
                    if(newvalue === "") {
                        newvalue = null;
                    }
                    this.$emit("update-email", newvalue);
                }
            },
            localAcceptEnvoiDemat: {
                get: function () {
                    return this.accept_envoi_demat;
                },
                set: function (newvalue) {
                    this.$emit("update-accept-envoi-demat", newvalue);
                }
            },
            localRenonciationPf: {
                get: function () {
                    return this.getReturnValueIfNull(this.renonciation_pf);
                },
                set: function (newvalue) {
                    this.$emit("update-renonciation-pf", newvalue);
                }
            },
            localExonerationCs: {
                get: function () {
                    return this.getReturnValueIfNull(this.exoneration_cs);
                },
                set: function (newvalue) {
                    this.$emit("update-exoneration-cs", newvalue);
                }
            },
            localExcluDocumentsLegaux: {
                get: function () {
                    return this.getReturnValueIfNull(this.is_exclu_documents_legaux);
                },
                set: function (newvalue) {
                    this.$emit("update-is-exclue-documents-legaux", newvalue);
                }
            },
        }
    }
</script>

<style scoped>
    .v-label.v-label--active.theme--light {
        overflow: visible;
        text-overflow: unset;
    }
</style>