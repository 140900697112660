<!-- VUETIFY2 - OK -->
<template>
    <div>
        <validation-observer ref="observer" v-slot="{ dirty, invalid }">
            <form @submit.prevent="editTauxFiscalite">
                <v-card class="elevation-1">
                    <loading-screen ref="loadingComponent"/>
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col cols="9">
                                {{ nameTauxFiscalite | capitalizeFirstLetterFilter}}
                            </v-col>
                            <v-col cols="3" class="text-end" style="max-height: 32px;">
                                <v-icon-button-with-tool-tip
                                    v-if="canEditTaux"
                                    detail="Enregistrer les modifications"
                                    :icon="'$vuetify.icons.save'"
                                    btnIsIcon tooltipTop
                                    :iconColorClass="dirty ? 'orange' : 'grey darken-1'"
                                    :disabled="invalid"
                                    type="submit"
                                />
                                <v-icon-button-with-tool-tip
                                    v-if="dirty"
                                    detail="Annuler les modifications"
                                    :icon="'$vuetify.icons.annulation'"
                                    btnIsIcon tooltipTop
                                    iconColorClass="grey darken-1"
                                    @click="resetTauxFiscalite"
                                />
                                <v-icon-button-with-tool-tip
                                    v-if="!dirty && canAddTaux"
                                    :detail="detailCreate"
                                    :icon="'$vuetify.icons.add'"
                                    btnIsIcon tooltipTop
                                    btnColorClass="primary"
                                    @click="openModaleCreation"
                                />
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        no-data-text="Aucun taux de fiscalité"
                        :items="tauxFiscalite"
                        height="336px"
                        :loading="loading"
                        :footer-props="footerProps"
                    >
                        <template v-slot:item="{ item }">
                            <tr>

                                <td class="text-center">{{ item.date_debut | dateIsoToFrFilter }}</td>

                                <td class="text-center">{{ item.date_fin | dateIsoToFrFilter }}</td>

                                <td class="text-right">
                                    <template v-if="item.is_editable && canEditTaux">
                                        <ValidationProvider name="Taux" :vid="'taux' + item.id"
                                                            v-slot="{ errors }"
                                                            rules="required|between:0,100">
                                            <v-formatted-number-text-field v-model="item.taux"
                                                                           class="my-0 py-0"
                                                                           prefixValue="%"
                                                                           :reverseValue="true"
                                                                           :errors="errors"
                                                                           :hideDetails="errors.length == 0"
                                            />
                                        </ValidationProvider>
                                    </template>
                                    <template v-else>
                                        {{ item.taux | roundPercentFilter(1, true)}}
                                        <v-tooltip left v-if="item.detail_composition">
                                            <template v-slot:activator="{ on }">
                                                <v-icon small left v-on="on">{{'$vuetify.icons.info'}}</v-icon>
                                            </template>
                                            <table>
                                                <tbody>
                                                    <tr v-for="detail in item.detail_composition" :key="detail.libelle">
                                                        <th class="text-left">{{detail.libelle}}</th>
                                                        <td class="text-center"><span style="display: inline-block; width: 30px;">{{detail.operateur}}</span></td>
                                                        <td class="text-right">{{detail.taux | roundPercentFilter(1, true)}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-left">Taux {{nameTauxFiscalite}}</th>
                                                        <td class="text-center"><span style="display: inline-block; width: 30px;">=</span></td>
                                                        <td class="text-right">{{item.taux | roundPercentFilter(1, true)}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </v-tooltip>
                                    </template>
                                </td>
                                <td class="text-center" v-if="canDeleteTaux">
                                    <v-custom-confirmation-dialog
                                        :disabled="!item.is_editable"
                                        message="Êtes-vous sûr(e) de vouloir supprimer ce taux de fiscalité ?"
                                        :isIcon="true"
                                        :iconValue="'$vuetify.icons.delete'"
                                        @action-confirmee="deleteTauxFiscalite(item)"
                                        tooltipDetailMessage="Supprimer le taux de fiscalité."
                                    />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </form>
        </validation-observer>
        <v-dialog v-model="dialogCreate" persistent max-width="600px">
            <v-card>
                <ValidationObserver ref="observerCreate" v-slot="{ invalid }">
                    <form @submit.prevent="addTauxFiscalite">
                        <v-card-title class="headline grey lighten-2" primary-title>Créer un taux de {{nameTauxFiscalite}}</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="6">
                                    <ValidationProvider name="Date d'entrée en vigueur" vid="date_debut"
                                                        v-slot="{ errors }"
                                                        :rules="{ required: true , date_after: dateDebutMin }">
                                        <date-field v-model="tauxFiscaliteCreate.date_debut"
                                                    label="Date d'entrée en vigueur"
                                                    :errors="errors"
                                                    :allowedDate="computeAllowedDates()"
                                        />
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider name="Taux" vid="taux"
                                                        v-slot="{ errors }"
                                                        rules="required|between:0,100">
                                        <v-formatted-number-text-field v-model="tauxFiscaliteCreate.taux"
                                                                       labelValue="Taux"
                                                                       prefixValue="%"
                                                                       :reverseValue="true"
                                                                       :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row justify="end" class="ma-0" dense>
                                <!-- Bouton modal -->
                                <v-col cols="4">
                                    <v-btn @click="closeModaleCreation" block>NON, ANNULER</v-btn>
                                </v-col>
                                <!-- Bouton modal -->
                                <v-col cols="4">
                                    <v-btn color="primary" type="submit" :disabled="invalid" block>OUI, CONFIRMER</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import _ from "lodash";
    import constantes from "@/utils/constantes";
    import DateField from "@/components/DateField";
    import internalApi from "@/api/internalApi";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import moment from "moment";
    import Utils from "@/utils.js";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog.vue";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField";
    import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
    /**
     * Composant affichant les fiscalités
     * @displayName datatable-taux-fiscalite
     */
    export default {
        name: "DatatableTauxFiscalite",
        components: {
            DateField,
            loadingScreen,
            VCustomConfirmationDialog,
            VFormattedNumberTextField,
            VIconButtonWithToolTip,
        },
        data() {
            return {
                loading: false,
                tauxFiscalite: [],
                lastTauxFiscalite: null,
                dialogCreate: false,
                detailCreate: `Créer un taux de fiscalité ${this.nameTauxFiscalite}`,
                tauxFiscaliteCreate: {
                    date_debut: null,
                    taux: null,
                },
                datePremiereCreationPossible: null,
                footerProps: {
                    itemsPerPageOptions: [
                        6,
                        -1,
                    ],
                },
            };
        },
        computed: {
            // Only get the value, no set because it is induced by saving the form
            headers() {
                const headers = [
                    {
                        text: "Date de début",
                        value: "date_debut",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: "Date de fin",
                        value: "date_fin",
                        align: "center",
                        sortable: false,
                    },
                    {
                        text: "Taux",
                        value: "taux",
                        align: "end",
                        sortable: false,
                    },
                ];

                if (this.canDeleteTaux) {
                    headers.push({
                        text: "Actions",
                        value: "actions",
                        align: "center",
                        sortable: false,
                    });
                }

                return headers;
            },
        },
        props: {
            apiTauxFiscalite: {
                type: Object,
                required: true,
            },
            nameTauxFiscalite: {
                type: String,
                required: false,
            },
            canAddTaux: {
                type: Boolean,
                required: false,
                default: false,
            },
            canDeleteTaux: {
                type: Boolean,
                required: false,
                default: false,
            },
            canEditTaux: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        mounted() {
            this.resetTauxFiscalite();
        },
        methods: {
            getTauxFiscalite() {
                this.loading = true;
                return this.apiTauxFiscalite.getAll().then(result => {
                    this.tauxFiscalite = result.taux_fiscalite_list;
                    this.datePremiereCreationPossible = result.date_premiere_creation_possible;
                    this.lastTauxFiscalite = this.getLastTauxFiscalite();
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            addTauxFiscalite() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);

                return this.apiTauxFiscalite.postAllWithToastConfirmation(this.tauxFiscaliteCreate).then(result => {
                    this.resetTauxFiscalite();
                }).catch(err => {
                    console.error(err);
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                    this.closeModaleCreation();
                });
            },
            editTauxFiscalite(resetFunction) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                this.$refs.observer.validate().then(success => {
                    const dataToSend = [];
                    this.tauxFiscalite.forEach(tf => {
                        if (tf.is_editable) {
                            dataToSend.push({id: tf.id, date_debut: tf.date_debut, taux: tf.taux});
                        }
                    });

                    return this.apiTauxFiscalite.putAllWithToastConfirmation(dataToSend).then(result => {
                        this.resetTauxFiscalite();
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                });
            },
            deleteTauxFiscalite(item) {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.suppression);
                return this.apiTauxFiscalite.deleteByIdWithToastConfirmation(item.id)
                    .then(result => {
                        this.resetTauxFiscalite();
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            getLastTauxFiscalite() {
                return this.tauxFiscalite.find(taux => {
                    return !_.isNil(taux.is_last) ? taux.is_last : false;
                });
            },
            resetTauxFiscalite() {
                return this.getTauxFiscalite().finally(() => {
                    this.$refs.observer.reset();
                });
            },
            openModaleCreation() {
                this.dialogCreate = true;

                if (!_.isNil(this.lastTauxFiscalite)) {
                    this.tauxFiscaliteCreate.taux = this.lastTauxFiscalite.taux;
                } else {
                    this.tauxFiscaliteCreate.taux = null;
                }

                this.dateDebutMin = this.datePremiereCreationPossible;
            },
            closeModaleCreation() {
                this.dialogCreate = false;
                this.dateDebutMin = null;
                this.tauxFiscaliteCreate.date_debut = null;
                this.tauxFiscaliteCreate.taux = null;
                this.$refs.observerCreate.reset();
            },
            /**
             * Retourne une fonction qui calcule les dates qui sont autorisées par rapport
             * à la date de dernière utilisation du taux
             * @returns {function}
             */
            computeAllowedDates() {
                if (!_.isNil(this.datePremiereCreationPossible)) {
                    return val => moment(this.datePremiereCreationPossible, 'YYYY-MM-DD').isSameOrBefore(val);
                } else {
                    return val => true;
                }
            }
        }
    }
</script>
