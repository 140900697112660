// <!-- VUETIFY2 - OK -->
import constantes from "@/utils/constantes";
import DeblocageAnticipe from "./DeblocageAnticipe";
import internalApi from "@/api/internalApi";

export default {
  extends: DeblocageAnticipe,
  methods: {
    submit() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
      let deblocageToSend = _.cloneDeep(this.deblocage);
      deblocageToSend.front_provided_montant_souscription_part = deblocageToSend.montant_souscription_part;
      delete deblocageToSend.montant_souscription_part;
      internalApi.deblocageAnticipe.postAllWithToastConfirmation(deblocageToSend)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          this.onBackClick();
        })
        .catch(err => {
          console.log(err);
          this.$refs.loadingComponent.resetLoading();
        });
    },
  }
};