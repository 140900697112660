<template>
    <base-layout :withHeader="false" :withFooter="false">
        <template v-slot:main="{}">
            <v-container>
                <v-row dense>
                    <v-col cols="12" sm="6" xl="4" xxl="2">
                        <datatable-taux-fiscalite
                            :apiTauxFiscalite="scicReductionImpotsApi"
                            nameTauxFiscalite="réduction d'impôts"
                            canAddTaux
                            canDeleteTaux
                            canEditTaux
                        />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import internalApi from "@/api/internalApi";
    import DatatableTauxFiscalite from "@/components/fiscalite/DatatableTauxFiscalite.vue";

    export default {
        name: "VScicTauxFiscalite",
        components: {
            BaseLayout,
            DatatableTauxFiscalite,
        },
        data() {
            return {
                tauxFiscaliteList: undefined,
                headers: [
                    {text: "Date de début", value: "date_debut", sortable: true},
                    {text: "Taux de réduction d'impôts", value: "taux_reduction_impots", sortable: false},
                    {text: "Date de fin", value: "date_fin", sortable: false},
                    {text: "Actions"},
                ],
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                scicReductionImpotsApi: internalApi.scicTauxFiscalite.scicReductionImpots,
            }
        },
    }
</script>
