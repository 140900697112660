// <!-- VUETIFY2 - OK -->
import constantes from "@/utils/constantes";
import internalApi from "@/api/internalApi";
import Souscription from "./Souscription";

export default {
  mounted() {
    this.isEditable = true;
    this.canEditDate = true;
  },
  extends: Souscription,
  methods: {
    /**
     * Process VeeValidate fields controls and then make API calls
     * to POST or PUT resource on backend
     */
    submitForm() {
      this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
      internalApi.souscription
        .postAllWithToastConfirmation(this.souscription)
        .then(result => {
          this.$refs.loadingComponent.resetLoading();
          if (result) {
            this.pushToMouvements();
          }
        }).catch(error => {
          console.error(error);
          this.$refs.loadingComponent.resetLoading();
        });
    }
  }
};
