// <!-- VUETIFY2 - OK -->
import Fiscalite from "@/views/common/fiscalite/Fiscalite";

export default {
    data() {
        return {
            univers: "PARTICIPATION",
            selectableExercice: false,
            selectableYear: true,
            selectableMonth: false,
            checkedValue: "PFU_2777_participation",
        };
    },
    extends: Fiscalite,
    methods: {

    }
};
