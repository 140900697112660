import defaultApi from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import csgCrdsApi from "./csgCrds";
import chargesSocialesApi from "./chargesSociales";
import forfaitSocialStandardApi from "./forfaitSocialStandard";
import forfaitSocialVersementImmediatApi from "./forfaitSocialVersementImmediat";
import prelevementForfaitaireApi from "./prelevementForfaitaire";

export default tauxFiscaliteApi;

function tauxFiscaliteApi() {
  const specificApi = {
    csgCrds: csgCrdsApi(),
    chargesSociales: chargesSocialesApi(),
    forfaitSocialStandard: forfaitSocialStandardApi(),
    forfaitSocialVersementImmediat: forfaitSocialVersementImmediatApi(),
    prelevementForfaitaire: prelevementForfaitaireApi(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.tauxFiscalite.baseUrl,
    []
  ));
}
