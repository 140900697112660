<!-- VUETIFY2 - OK -->
<template>
    <v-container fluid>
        <v-row justify="space-between">
            <v-col md="5" lg="5" xl="3">
                <ValidationProvider vid="dateAccesSocietariat"
                                    name="Date d'accès au sociétariat"
                                    :rules="{ required: this.modifier_donnees_associe}"
                                    v-slot="{ errors }">
                    <date-field v-model="localDateAccesSocietariat"
                                label="Date d'accès au sociétariat"
                                :errors="errors"
                                :disabled="!modifier_donnees_associe"
                    />
                </ValidationProvider>
            </v-col>
            <v-col md="6" lg="6" xl="5">
                <date-field
                    v-model="localDateEvtDeclencheurSocietariat"
                    label="Date de l'évènement déclencheur du sociétariat"
                    :disabled="!modifier_donnees_associe"
                />
            </v-col>
            <v-col md="5" lg="5" xl="3">
                <v-text-field
                    v-model.trim="localLibEvtDeclencheurSocietariat"
                    label="Evènement déclencheur du sociétariat"
                    :disabled="!modifier_donnees_associe"/>
            </v-col>
        </v-row>
        <v-row justify="space-between">
            <v-col md="5" lg="5" xl="4">
                <date-field v-model="localDatePerteQualiteAssocie"
                            label="Date de perte de la qualité d'associé(e)"
                            :disabled="!modifier_donnees_associe"/>
            </v-col>
            <v-col md="6">
                <v-select v-model="localMotifPerteQualiteAssocie"
                          label="Evènement de perte de la qualité d'associé(e)"
                          :items="selectItems.motif_perte_qualite_associe"
                          :disabled="!modifier_donnees_associe"
                          clearable/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <v-row align="center">
                    <v-col cols="11">
                        <v-checkbox v-model="localRenonciationIps"
                                    label="Renonciation aux intérêts aux parts sociales"
                                    false-value="false"
                                    :disabled="!modifier_donnees_associe"/>
                    </v-col>
                    <v-col cols="1" ml-4>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon small left v-on="{...on}">{{"$vuetify.icons.info"}}</v-icon>
                            </template>
                            <span v-html="messageRenonciationIPS"/>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" xxl="2">
                <v-row align="center">
                    <v-col cols="11">
                        <v-checkbox v-model="localBlocageSouscriptions"
                                    label="Blocage des nouvelles souscriptions"
                                    false-value="false"
                                    :disabled="!modifier_donnees_associe"/>
                    </v-col>
                    <v-col cols="1" ml-4>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon small left v-on="{...on}">{{"$vuetify.icons.info"}}</v-icon>
                            </template>
                            <span v-html="messageBlocageSouscriptions"/>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6">
                <ValidationProvider vid="localTypeDroitDeVote"
                                    name="Droit de vote"
                                    :rules="{ required: (this.modifier_donnees_associe && !this.disable_type_droit_de_vote)}"
                                    v-slot="{ errors }">
                    <v-select label="Droit de vote"
                              :error-messages="errors"
                              v-model="localTypeDroitDeVote"
                              :items="selectItems.type_droit_de_vote"
                              :disabled="!modifier_donnees_associe || disable_type_droit_de_vote"
                    />
                </ValidationProvider>
            </v-col>
        </v-row>
        <v-scic-donnees-associe :isEditable="modifier_donnees_associe"
                                :scicCategorieAssocie="localScicCategorieAssocie"
                                :scicSousCategorieAssocie="localScicSousCategorieAssocie"
                                :scicCollegeAssocie="localScicCollegeAssocie"
                                @update-scic-categorie-associe="localScicCategorieAssocie = $event"
                                @update-scic-sous-categorie-associe="localScicSousCategorieAssocie = $event"
                                @update-scic-college-associe="localScicCollegeAssocie = $event"
        />
    </v-container>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import DateField from "@/components/DateField.vue";
    import VScicDonneesAssocie from "@/components/scic/VScicDonneesAssocie.vue";
    import Utils from "@/utils.js";

    /**
     * Composant pour afficher/modifier les données d'un associé
     * @display_name Capital - VDonneesAssocie
     */
    export default {
        components: {
            DateField,
            VScicDonneesAssocie,
        },
        name: "VDonneesAssocie",
        props: {
            /**
             * Détermine si la personne est associé ou non (et donc autorise ou non
             * la modification des champs)
             */
            modifier_donnees_associe: {
                type: Boolean,
                default: false
            },
            /**
             * La date à laquelle la personne accède au sociétariat
             */
            date_acces_societariat: {
                type: [Date, String],
                default: null
            },
            /**
             * La date de l'évènement qui a déclenché l'accès au sociétariat
             */
            date_evt_declencheur_societariat: {
                type: [Date, String],
                default: null
            },
            /**
             * Le libellé de l'évènement qui a déclenché l'accès au sociétariat
             */
            lib_evt_declencheur_societariat: {
                type: String,
                default: null
            },
            /**
             * La date à laquelle la personne a cessé d'être associée
             */
            date_perte_qualite_associe: {
                type: [Date, String],
                default: null
            },
            /**
             * Le motif pour lequel la personne a cessé d'être associée
             */
            motif_perte_qualite_associe: {
                type: String,
                default: null
            },
            /**
             * Détermine si l'associé renonce aux intérêts aux parts sociales ou non
             */
            renonciation_ips: {
                type: Boolean,
                default: null
            },
            /**
             * Détermine si la personne a le choix de choisir son type de droit de vote
             */
            disable_type_droit_de_vote: {
                type: Boolean,
                default: false
            },
            /**
             * Détermine si la personne est bloquée pour de nouvelles souscriptions
             */
            blocage_souscriptions: {
                type: Boolean,
                default: false
            },
            /**
             * Le type de droit de vote de l'associé (unitaire, indexé)
             */
            type_droit_de_vote: {
                type: String,
                default: null
            },
            /**
             * La catégorie d'associé SCIC
             */
            scicCategorieAssocie: {
                type: String,
                default: null
            },
            /**
             * La sous catégorie d'associé SCIC
             */
            scicSousCategorieAssocie: {
                type: String,
                default: null
            },
            /**
             * La collège d'associé SCIC
             */
            scicCollegeAssocie: {
                type: String,
                default: null
            },
        },
        mounted() {
            this.getSelectItems();
        },
        data() {
            return {
                messageRenonciationIPS: "Les associé(e)s qui renoncent aux intérêts aux parts sociales sont exclu(e)s du calcul de la répartition des intérêts aux parts sociales.",
                messageRenonciationPrelevementForfaitaire: "Les associé(e)s qui renoncent au prélèvement forfaitaire sont exclu(e)s de ce prélèvement. Ils/Elles perçoivent donc le montant net des intérêts aux parts sociales qui leur est attribué.",
                messageBlocageSouscriptions: "Les associé(e)s pour lesquel(le)s le blocage des nouvelles souscriptions est activé ne peuvent plus souscrire de parts sociales.",
                selectItems: {},
            }
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getReturnValueIfNull(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            /**
             * Récupère la liste des possiblités pour les champs à choix multiples
             * @returns {{string: [string]}}
             */
            getSelectItems() {
                return internalApi.associe.getLabels().then(result => {
                    let {associe} = result;
                    this.selectItems = {
                        type_associe: Utils.fromLabelToSelect(associe.type_associe),
                        motif_perte_qualite_associe: Utils.fromLabelToSelect(
                            associe.motif_perte_qualite_associe
                        ),
                        type_droit_de_vote: Utils.fromLabelToSelect(
                            associe.type_droit_de_vote
                        ),
                    };
                });
            },
        },
        computed: {
            localDateAccesSocietariat: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_acces_societariat);
                },
                set: function (newvalue) {
                    this.$emit("update-date-acces-societariat", newvalue);
                }
            },
            localDateEvtDeclencheurSocietariat: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_evt_declencheur_societariat);
                },
                set: function (newvalue) {
                    this.$emit("update-date-evt-declencheur-societariat", newvalue);
                }
            },
            localLibEvtDeclencheurSocietariat: {
                get: function () {
                    return this.getReturnValueIfNull(this.lib_evt_declencheur_societariat);
                },
                set: function (newvalue) {
                    this.$emit("update-lib-evt-declencheur-societariat", newvalue);
                }
            },
            localDatePerteQualiteAssocie: {
                get: function () {
                    return this.getReturnValueIfNull(this.date_perte_qualite_associe);
                },
                set: function (newvalue) {
                    this.$emit("update-date-perte-qualite-associe", newvalue);
                }
            },
            localMotifPerteQualiteAssocie: {
                get: function () {
                    return this.getReturnValueIfNull(this.motif_perte_qualite_associe);
                },
                set: function (newvalue) {
                    this.$emit("update-motif-perte-qualite-associe", newvalue);
                }
            },
            localRenonciationIps: {
                get: function () {
                    return this.getReturnValueIfNull(this.renonciation_ips);
                },
                set: function (newvalue) {
                    this.$emit("update-renonciation-ips", newvalue);
                }
            },
            localTypeDroitDeVote: {
                get: function () {
                    return this.getReturnValueIfNull(this.type_droit_de_vote);
                },
                set: function (newvalue) {
                    this.$emit("update-type-droit-de-vote", newvalue);
                }
            },
            localBlocageSouscriptions: {
                get: function () {
                    return this.getReturnValueIfNull(this.blocage_souscriptions);
                },
                set: function (newvalue) {
                    this.$emit("update-blocage-souscriptions", newvalue);
                }
            },
            localScicCategorieAssocie: {
                get: function () {
                    return this.getReturnValueIfNull(this.scicCategorieAssocie);
                },
                set: function (newvalue) {
                    this.$emit("update-scic-categorie-associe", newvalue);
                }
            },
            localScicSousCategorieAssocie: {
                get: function () {
                    return this.getReturnValueIfNull(this.scicSousCategorieAssocie);
                },
                set: function (newvalue) {
                    this.$emit("update-scic-sous-categorie-associe", newvalue);
                }
            },
            localScicCollegeAssocie: {
                get: function () {
                    return this.getReturnValueIfNull(this.scicCollegeAssocie);
                },
                set: function (newvalue) {
                    this.$emit("update-scic-college-associe", newvalue);
                }
            },
        }
    }

</script>