<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="120px">
        <template slot="header">
            <h1>Conformité</h1>
            <v-row no-gutters>
                <v-col md="2" xl="2" lg="2">
                    <date-field v-model="controlDate" :clearable="false" @input="addPromiseResponseToCheckList"/>
                </v-col>
            </v-row>
        </template>
        <template slot="main">
            <v-container>
                <v-expansion-panels>
                    <conformite-header v-for="checkHeader in toCheckHeader"
                                       :key="checkHeader.id"
                                       :title="checkHeader.title"
                                       :toCheckList="checkHeader.checkList"
                    />
                </v-expansion-panels>
            </v-container>
        </template>
    </base-layout>
</template>

<script>
    import DateField from "@/components/DateField";
    import ConformiteHeader from "@/views/capital/conformite/ConformiteHeader";
    import internalApi from "@/api/internalApi";
    import moment from "moment";
    import BaseLayout from "@/components/BaseLayout";

    /**
     * Composant affichant l'ensemble des règles de conformité
     * @displayName Capital - Conformité
     */
    export default {
        components: {
            BaseLayout,
            ConformiteHeader,
            DateField
        },
        data() {
            return {
                controlDate: moment().format("YYYY-MM-DD"),
                toCheckHeader: [],
                capCompany: undefined,
                droits_de_vote_associes_non_coop_pourcentage_plafond: undefined,
                droits_de_vote_associes_coop_pourcentage_plafond: undefined,
            };
        },
        mounted() {
            let promise_cap_company = this.getCurrentCapCompany();
            Promise.all([promise_cap_company]).then(res => {
                this.addPromiseResponseToCheckList(this.controlDate);
            });
        },
        methods: {
            getCurrentCapCompany() {
                return internalApi.capCompany.getCurrentStatutCapCompany()
                    .then(result => {
                        this.capCompany = result;
                        this.droits_de_vote_associes_non_coop_pourcentage_plafond = result.droits_de_vote_associes_non_coop_pourcentage_plafond;
                        this.droits_de_vote_associes_coop_pourcentage_plafond = result.droits_de_vote_associes_coop_pourcentage_plafond;
                        this.toCheckHeader = [
                            this.headerMontantCapital,
                            this.headerRepartitionCapital,
                            this.createHeaderDroitsVote()
                        ];
                    });
            },
            createHeaderDroitsVote() {
                return {
                    title: "Droits de vote",
                    checkList: [
                        {
                            id: 1,
                            title: `Les associé(e)s externes (hors sociétés coopératives) doivent détenir ${this.droits_de_vote_associes_non_coop_pourcentage_plafond}% ou moins des droits de vote`,
                            promise: internalApi.controleStatus.checkDroitsVoteAssocieExterneHorsCoop,
                            state: undefined,
                            message: ""
                        },
                        {
                            id: 2,
                            title: `Les associé(e)s externes (dont sociétés coopératives) doivent détenir ${this.droits_de_vote_associes_coop_pourcentage_plafond}% ou moins des droits de vote`,
                            promise: internalApi.controleStatus.checkDroitsVoteAssocieExterneDontCoop,
                            state: undefined,
                            message: ""
                        }
                    ]
                };
            },
            addPromiseResponseToCheckList(date) {
                for (let header of this.toCheckHeader) {
                    for (let check of header.checkList) {
                        check.state = undefined;
                        check.message = "";
                        check.promise(date, this.capCompany.id).then(resolve => {
                            check.state = resolve.checkIsValid;
                            check.message = resolve.message;
                        });
                    }
                }
            }
        },
        computed: {
            headerMontantCapital() {
                return {
                    title: "Montant du Capital",
                    checkList: [
                        {
                            id: 1,
                            title: "Le capital social doit être supérieur au seuil plancher défini par les statuts",
                            promise: internalApi.controleStatus.getCapitalSuperieurPlancher,
                            state: undefined,
                            message: ""
                        },
                        {
                            id: 2,
                            title: "Le capital social doit être supérieur au quart du capital le plus élevé atteint par l'entreprise",
                            promise: internalApi.controleStatus.getCapitalSuperieurQuartCapitalMax,
                            state: undefined,
                            message: ""
                        }
                    ]
                };
            },
            headerRepartitionCapital() {
                return {
                    title: "Répartition du Capital",
                    checkList: [
                        {
                            id: 1,
                            title: "Les salarié(e)s et ex-salarié(e)s doivent détenir plus de 50% du capital",
                            promise: internalApi.controleStatus.getCapitalSalariesExSalariesSuperieurMoitieCapital,
                            state: undefined,
                            message: ""
                        },
                        {
                            id: 2,
                            title: "Aucun(e) associé(e) ne doit détenir plus de 50% du capital",
                            promise: internalApi.controleStatus.getCapitalAssocieInferieurMoitieCapital,
                            state: undefined,
                            message: ""
                        }
                    ]
                };
            }
        }
    };
</script>
