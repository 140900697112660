<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formDocuments" v-slot="{ validate, errors, reset }">
        <base-layout :withHeader="false" id="barretteFormule">
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <v-container v-if="isAdministrateur">
                    <chips-legends />
                    <v-divider class="my-4"></v-divider>
                    <v-row>
                        <v-col>
                            <h3>Formule de la barrette</h3>
                        </v-col>
                    </v-row>
                    <ValidationProvider vid="barrette_formule"
                                        name="Formule de la barrette"
                                        rules="max:5000"
                                        v-slot="{ errors }">
                        <v-textarea
                            v-model="texte_barrette_formule"
                            filled
                            label="Formule de la barrette"
                            auto-grow
                            :error-messages="errors[0]"
                        ></v-textarea>
                    </ValidationProvider>
                </v-container>
            </template>
            <template v-slot:footer>
                <v-row no-gutters justify="end">
                    <!-- Petits boutons -->
                    <v-col md="6" lg="6" xl="4">
                        <v-save-buton-with-validation :validateForm="validate"
                                                      :resetForm="reset"
                                                      :errorsList="errors"
                                                      @emit-cancel="cancelBarretteFormule()"
                                                      @emit-valid-save="saveBarretteFormule()"/>
                    </v-col>
                </v-row>
            </template>    
        </base-layout>
    </ValidationObserver>
</template>

<script>
import internalApi from "@/api/internalApi";
import constantes from "@/utils/constantes";
import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
import ChipsLegends from "@/components/formuleBarretteIdentificationPagePdf/ChipsLegends.vue";
import BaseLayout from "@/components/BaseLayout";
import _ from "lodash";
import authService from "@/services/authService";
import rolesList from "@/router/rolesList";
import loadingScreen from "@/components/LoadingScreen.vue";


export default {
    name: "FormuleBarretteIdentificationPagePdf",
    components: {
        VSaveButonWithValidation,
        BaseLayout,
        loadingScreen,
        ChipsLegends
    },
    data() {
        return {
            texte_barrette_formule: "",
            formats_disponibles: {},
            placeholders_disponibles: {},
            isAdministrateur: false,
        };
    },
    mounted() {
        this.fetchBarretteFormule();
        const currentRoles = authService.getCurrentRoles();
        this.isAdministrateur = !_.isEmpty(_.intersection([rolesList.ADMINISTRATEUR], currentRoles));
    },
    computed: {
    },
    methods: {
        fetchBarretteFormule() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            internalApi.barretteIdentificationDocumentsPdf.getOnlyOne()
            .then(result => {
                this.texte_barrette_formule = result.formule_barrette;
             }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        },
        cancelBarretteFormule() {
            this.fetchBarretteFormule();
        },
        saveBarretteFormule() {
            let payload = {
                "formule_barrette": this.texte_barrette_formule,
            };
            this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
            internalApi.barretteIdentificationDocumentsPdf.putOnlyOneWithToastConfirmation(payload)
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
        }
    }
}
</script>
