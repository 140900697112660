import defaultApi from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import scicReductionImpotsApi from "./scicReductionImpots";

export default tauxFiscaliteApi;

function tauxFiscaliteApi() {
  const specificApi = {
    scicReductionImpots: scicReductionImpotsApi(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.tauxFiscalite.baseUrl,
    []
  ));
}
