import defaultApi, {ACCEPTED_METHODS} from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";


export default prelevementForfaitaireApi;

function prelevementForfaitaireApi()  {
  const specificApi = {
    getValueAtDate: getValueAtDate(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.tauxFiscalite.prelevementForfaitaire.baseUrl,
    [
      ACCEPTED_METHODS.getAll,
    ]
  ));
}

function getValueAtDate() {
  function getValueAtDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.tauxFiscalite.prelevementForfaitaire.getValueAtDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getValueAtDate;
}
