import internalApiRoutes from "@/api/internalApi/apiRoutes";
import defaultApi, {ACCEPTED_METHODS} from "../defaultApi";

export default barretteIdentificationDocumentsPdfApi;

function barretteIdentificationDocumentsPdfApi() {
  const specificApi = {};
  return Object.assign(specificApi, defaultApi(internalApiRoutes.barretteIdentificationDocumentsPdf.baseUrl, [
    ACCEPTED_METHODS.getLabels,
    ACCEPTED_METHODS.getOnlyOne,
    ACCEPTED_METHODS.putOnlyOne,
  ]));
}
