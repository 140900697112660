<template>
    <ValidationObserver v-slot="{ validate, errors }">
    <base-layout :withHeader="false" datatableExcludedSizeDefault="190px">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-container>
                <v-expansion-panels inset>
                    <v-expansion-panel>
                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                            <v-row align="center" no-gutters>
                                <v-col md="5" lg="5" xl="3">
                                    <h2>Périodes de travail</h2>
                                </v-col>
                                <v-spacer/>
                                <v-col lg="2" xl="1">
                                    <v-export-file detail="Exporter les périodes de travail pour l'exercice"
                                                   @export-file="exportPeriodesTravail"/>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-datatable-periodes-travail :exercice="exercice"
                                                          :datatableSize="datatableSize"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="repartitionSalaireActive || repartitionPresenceActive || repartitionAncienneteActive" :disabled="!showSalairesPresences">
                        <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                            <v-row align="center" no-gutters>
                                <v-col md="5" lg="5" xl="3">
                                    <h2>Données des bénéficiaires</h2>
                                </v-col>
                                <v-spacer/>
                                <v-col lg="2" xl="1" v-if="!disabled">
                                    <v-import-export-file detail="Importer des données des bénéficiaires"
                                                   :typesFile="getTypesExtensionsFiles().excel"
                                                   @import-file="function(formData){onSalairePresencesImport(formData)}"
                                                   :allowedExport="!disabled"
                                                   exportDetail="Télécharger le modèle d'import des données des bénéficiaires"
                                                   @export-file="exportSalairePresences"/>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager v-if="showSalairesPresences">
                            <v-datatable-pb-salaire-presences v-if="isPb"
                                                              :exerciceId="exercice.id"
                                                              :datatableSize="datatableSize"
                                                              :repartitionPresenceActive="repartitionPresenceActive"
                                                              :repartitionSalaireActive="repartitionSalaireActive"
                                                              :repartitionAncienneteActive="repartitionAncienneteActive"
                                                              :disabled="disabled"
                                                              ref="datatablePbSalairePresences"
                            />
                            <v-datatable-inte-salaire-presences v-else-if="isInte"
                                                                :exerciceId="exercice.id"
                                                                :datatableSize="datatableSize"
                                                                :repartitionPresenceActive="repartitionPresenceActive"
                                                                :repartitionSalaireActive="repartitionSalaireActive"
                                                                :disabled="disabled"
                                                                ref="datatableInteSalairePresences"
                            />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
        </template>
        <template v-slot:footer>
            <v-row v-if="!disabled && (repartitionPresenceActive || repartitionSalaireActive || repartitionAncienneteActive)" justify="end" no-gutters>
                <!-- Petit bouton -->
                <v-col md="3" lg="4" xl="2">
                    <v-btn block color="secondary" @click="saveSalairePresences(validate, errors)">
                        <v-icon dark left>{{ "$vuetify.icons.save" }}</v-icon>
                        Enregistrer les données des bénéficiaires
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
    </ValidationObserver>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import VDatatablePeriodesTravail from "@/components/exercice/VDatatablePeriodesTravail";
    import VDatatablePbSalairePresences from "@/components/exercice/VDatatablePbSalairePresences";
    import VDatatableInteSalairePresences from "@/components/exercice/VDatatableInteSalairePresences";
    import VImportExportFile from "@/components/VImportExportFile";
    import VExportFile from "@/components/VExportFile";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import {TOAST} from "@/plugins/toast/definition";

    export default {
        name: "DonneesBeneficiairesAbstract",
        abstract: true,
        components: {
            VImportExportFile,
            VDatatablePbSalairePresences,
            VDatatableInteSalairePresences,
            BaseLayout,
            VDatatablePeriodesTravail,
            loadingScreen,
            VExportFile
        },
        data() {
            return {
                company: {
                    part_repartition_selon_presence: 0,
                    part_repartition_selon_salaire: 0,
                },
            }
        },
        props: {
            exercice: {
                type: Object,
                required: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            /***
             * Authorise ou non à consulter les salaires et présences
             */
            showSalairesPresences: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            repartitionPresenceActive: function () {
                return this.company.part_repartition_selon_presence > 0;
            },
            repartitionSalaireActive: function () {
                return this.company.part_repartition_selon_salaire > 0;
            },
            repartitionAncienneteActive: function() {
                // Abstract method
                return undefined;
            },
            isPb: function () {
                // Abstract method
                return undefined;
            },
            isInte: function () {
                // Abstract method
                return undefined;
            },
        },
        mounted() {
            this.getCurrentUnivCompany();
        },
        methods: {
            getRefDatatableSalairePresences() {
                if (this.isPb) {
                    return this.$refs.datatablePbSalairePresences;
                } else if (this.isInte) {
                    return this.$refs.datatableInteSalairePresences;
                } else {
                    return undefined;
                }
            },
            /**
             * Retourne la constante des types d'extensions de fichiers
             */
            getTypesExtensionsFiles() {
                return constantes.extensions_fichiers;
            },
            saveSalairePresences(validate, errors) {
                validate().then(
                    success => {
                        if (success) {
                            this.getRefDatatableSalairePresences().saveSalairePresences();
                        } else {
                            this.showErrorToast(errors);
                        }
                    }
                );
            },
            /**
             * Display errors from the table
             */
            showErrorToast(raw_errors) {
                const errors = Object.keys(raw_errors).flatMap(key => raw_errors[key]);
                errors.forEach(error => TOAST.error("Champ requis manquant", error));
            },

            /**
             * Obtenir la company courante
             */
            getCurrentUnivCompany() {
                // Abstract method
            },
            /**
             * Exporte les données de la datatable sous forme d'un fichier excel
             */
            exportSalairePresences() {
                // Abstract method
            },
            onSalairePresencesImport(formData) {
                // Abstract method
            },
            /**
             *  Export les données périodes de travail pour un exercice
             */
            exportPeriodesTravail() {
                // Abstract method
            },

        }
    }
</script>