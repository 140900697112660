import defaultApi from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import cerfa2561Api from "./cerfa2561";

export default documentsLegauxApi;

function documentsLegauxApi() {
  const specificApi = {
    cerfa2561: cerfa2561Api(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.tauxFiscalite.baseUrl,
    []
  ));
}
