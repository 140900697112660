<!-- VUETIFY2 - OK -->
<template>
    <div>
        <loading-screen ref="loadingComponent"/>
        <v-row>
            <v-col cols="12" sm="6" md="9" lg="9" xl="10">
                <h3 style="display: inline-block;">Cerfa 2561 ter</h3>
                <v-tooltip top
                           :open-on-hover="!$vuetify.breakpoint.smAndDown"
                           :open-on-click="$vuetify.breakpoint.smAndDown"
                >
                    <template v-slot:activator="{ on }">
                        <v-icon class="pl-2" v-on="on" small left>{{ "$vuetify.icons.info" }}</v-icon>
                    </template>
                    <span v-html="`Le Cerfa 2561 ter fait état des revenus capitaux mobiliers versés et du prélèvement forfaitaire associé.`"/>
                </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-btn color="primary"
                       @click="openModale"
                       block
                >
                    Télécharger
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <ValidationObserver ref="observerDownload" v-slot="{ invalid }">
                    <form @submit.prevent="downloadCerfa2561Ter">
                        <v-card-title class="headline grey lighten-2" primary-title>Télécharger le Cerfa 2561 ter</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <ValidationProvider vid="anneeFiscale"
                                                        name="Millésime"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                        <v-years-list v-model="anneeFiscale"
                                                      label="Millésime"
                                                      :minYear="2016"
                                                      :maxYear="2100"
                                                      :errors="errors"
                                        />
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row justify="end" class="ma-0" dense>
                                <!-- Bouton modal -->
                                <v-col cols="12" sm="6" md="4">
                                    <v-btn @click="closeModale" block>Fermer</v-btn>
                                </v-col>
                                <!-- Bouton modal -->
                                <v-col cols="12" sm="6" md="4">
                                    <v-btn color="primary" type="submit" :disabled="invalid" block>Télécharger</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import authService from "@/services/authService";
    import internalApi from "@/api/internalApi";
    import constantes from "@/utils/constantes";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VYearsList from "@/components/VYearsList";

    export default {
        name: "download-cerfa-2561-ter-individuel",
        components: {
            loadingScreen,
            VYearsList,
        },
        data() {
            return {
                dialog: false,
                anneeFiscale: undefined,
            };
        },
        methods: {
            /**
             * Vérifie l'accès à l'univers capital
             */
            hasAccessCapital: function () {
                return authService.hasAccessCapital();
            },
            downloadCerfa2561Ter() {
                this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
                return internalApi.documentsLegaux.cerfa2561.downloadCerfa2561terIndividuel(this.anneeFiscale)
                    .then(response => {
                        Utils.downloadFile(response, `Cerfa 2561 ter ${this.anneeFiscale}.pdf`, Utils.pdfMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.closeModale();
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            openModale() {
                this.dialog = true;
            },
            closeModale() {
                this.dialog = false;
                this.anneeFiscale = null;
                this.$refs.observerDownload.reset();
            },
        }
    }
</script>