<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withFooter="false" headerSize="96px">
        <template v-slot:header>
            <h2>Documents légaux</h2>
            <v-tabs v-model="currentTab">
                <v-tab key="cerfa_2561_2561_ter">Cerfa 2561 / 2561 ter</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="cerfa_2561_2561_ter">
                    <Cerfa2561/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import BaseLayout from "@/components/BaseLayout";
    import Cerfa2561 from "@/views/common/documentsLegaux/Cerfa2561";
    import _ from "lodash";

    export default {
        components: {
            BaseLayout,
            Cerfa2561,
        },
        data() {
            return {
                currentTab: undefined,
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>
