<template>

    <ValidationObserver v-slot="{ validate, errors }" ref="form">
        <base-layout datatableExcludedSizeDefault="200px"
                     datatableExcludedSizeXsOnly="0px"
                     datatableExcludedSizeSmOnly="0px"
                     datatableExcludedSizeMdOnly="0px"
                     datatableExcludedSizeLgOnly="300px"
                     :headerSize="$vuetify.breakpoint.smAndDown ? '50px' : '60px'"
        >
            <template v-slot:header>
                <loading-screen ref="loadingComponent"/>
                <h1 class="ml-3">Déblocages groupés</h1>
            </template>
            <template v-slot:main="{ datatableSize }">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <ValidationProvider vid="date_mouvement"
                                                name="Date de l'opération"
                                                rules="required"
                                                v-slot="{ errors }">
                                <date-field v-model="date_deblocage"
                                            label="Date du déblocage"
                                            :errors="errors"
                                            :clearable="false"
                                            :disabled="!isEditable"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-pb-supports-list v-model="support_id"
                                             label="Support"
                                             :supportFilter="'actif_placement_interne'"
                                             :isEnabled="isEditable"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-text-field v-model="search"
                                          append-icon="search"
                                          label="Nom du bénéficiaire"
                                          single-line/>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="4" xl="3" xxl="2">
                            <v-taux-remu-for-deblocage  :tauxRemu="taux_de_remuneration_interets_courus"
                                                        :isEditable="isEditable"
                                                        :dateReferenceTauxRemu="date_deblocage"
                                                        @update-taux-remu="taux_de_remuneration_interets_courus = $event"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="selected.length > 0">
                        <v-col>
                            <span v-if="selected.length === 1">Actuellement, <b>{{selected.length}}</b> tiers est exclu des déblocages groupés.</span>
                            <span v-else>Actuellement, <b>{{selected.length}}</b> tiers sont exclus des déblocages groupés.</span>
                        </v-col>
                    </v-row>
                    <v-data-table v-model="selected"
                                  :footer-props="footerProps"
                                  :headers="headers"
                                  :items="deblocagesList"
                                  :loading="isDataLoading"
                                  fixed-header
                                  :height="datatableSize"
                                  class="elevation-1"
                                  no-data-text="Aucun déblocage."
                                  :options.sync="pagination"
                                  :search="search">
                        <template v-slot:item="{ isSelected, select, item }">
                            <tr>
                                <td>
                                    <v-simple-checkbox :ripple="false"
                                                       :value="!isSelected"
                                                       @input="select(!$event)"/>
                                </td>
                                <td class="text-lg-left">
                                    {{ item.name }}
                                </td>
                                <td class="text-lg-left">
                                    {{ item.participation | roundEuroFilter }}
                                </td>
                                <td class="text-lg-left">
                                    {{ item.interets_bruts | roundEuroFilter }}
                                </td>
                                <td class="text-lg-left">
                                    {{ item.montant_interets_courus | roundEuroFilter }}
                                </td>
                                <td class="text-lg-left">
                                    {{ item.total_with_interets_courus | roundEuroFilter }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-container>
            </template>
            <template slot="footer">
                <v-row dense justify="end">
                    <!-- Petits boutons -->
                    <v-col cols="6" md="3" xl="2">
                        <v-btn @click="onBackClick" block>Retour</v-btn>
                    </v-col>
                    <v-col cols="6" md="3" xl="2">
                        <v-custom-confirmation-dialog
                            :message="buildConfirmationMessage()"
                            @action-confirmee="submit()"
                            :externalValidateForm="validate"
                            openButtonMessage="Débloquer"
                            title="Valider les déblocages en masse"
                            :externalErrorsList="errors"/>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
    </ValidationObserver>
</template>
<script>
    import BaseLayout from "@/components/BaseLayout";
    import DateField from "@/components/DateField";
    import VPbSupportsList from "@/components/supportsList/VPbSupportsList";
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import loadingScreen from "@/components/LoadingScreen";
    import constantes from "@/utils/constantes";
    import _ from "lodash";
    import VCustomConfirmationDialog from "@/components/VCustomConfirmationDialog";
    import VTauxRemuForDeblocage from "@/components/tauxRemunerationSupport/VTauxRemuForDeblocage"

    export default {
        name: "DeblocagesGroupes",
        components: {
            BaseLayout,
            DateField,
            VPbSupportsList,
            loadingScreen,
            VCustomConfirmationDialog,
            VTauxRemuForDeblocage,
        },
        data() {
            return {
                confirmationDialog: false,
                search: undefined,
                isEditable: true,
                support_id: undefined,
                date_deblocage: undefined,
                taux_de_remuneration_interets_courus: undefined,
                deblocagesList: [],
                total_items: -1,
                pagination: {
                    sortBy: ["name"],
                    sortDesc: [false],
                    page: 1,
                    itemsPerPage: 10,
                },
                footerProps: {
                    itemsPerPageOptions: [
                        10,
                        20,
                        50,
                    ],
                },
                isDataLoading: false,
                selected: [],
                excluded: [],
                headers: [
                    {
                        text: "",
                        value: "cb",
                        width: "1%",
                    },
                    {
                        text: "Bénéficiaire",
                        value: "name",
                        align: "left"
                    },
                    {
                        text: "Participation",
                        value: "participation",
                        align: "left"
                    },
                    {
                        text: "Intérêts échus bruts",
                        value: "interets_brut",
                        align: "left"
                    },
                    {
                        text: "Intérêts courus",
                        value: "interets_courus",
                        align: "left"
                    },
                    {
                        text: "Total",
                        value: "total_with_interets_courus",
                        align: "left"
                    }
                ],
            }
        },
        watch: {
            support_id() {
                this.onChange();
            },
            taux_de_remuneration_interets_courus() {
                this.onChange();
            },
            date_deblocage() {
                this.onChange();
            }

        },
        methods: {

            /**
             * retourne aux déblocages
             */
            onBackClick() {
                this.$router.push({name: "pb-deblocages"});
            },
            fetchDeblocages() {
                if (this.support_id !== undefined && this.date_deblocage !== undefined) {
                    this.isDataLoading = true;
                    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                    internalApi.deblocageTerme.getDeblocagesGroupes({
                        support_id: this.support_id,
                        date_deblocage: Utils.displayDateToIsoFormat(this.date_deblocage),
                        taux_de_remuneration_interets_courus: this.taux_de_remuneration_interets_courus,
                    })
                        .then(result => {
                            this.deblocagesList = result.deblocages;
                            this.total_items = result.total_items;
                            this.selected = [];
                            this.resetPagination();
                        })
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.isDataLoading = false;
                            this.$refs.loadingComponent.resetLoading();
                            this.isDataLoading = false;
                        });
                }
            },
            /**
             * enregistre les déblocages sélectionnées
             */
            submit() {
                let body = {
                    date_deblocage: this.date_deblocage,
                    support_id: this.support_id,
                    taux_de_remuneration_interets_courus: this.taux_de_remuneration_interets_courus,
                    excluded_beneficiaires: _.flatMap(this.selected, function (item) {
                        return item.id
                    })
                }
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);
                internalApi.deblocageTerme.postDeblocagesGroupes(body)
                    .then(result => {
                        this.$refs.loadingComponent.resetLoading();
                        this.onBackClick();
                    })
                    .catch(err => {
                        console.log(err);
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            resetPagination() {
                this.pagination.page = 1;
                this.search = undefined;
            },
            onChange() {
                this.resetPagination();
                this.fetchDeblocages();
            },
            buildConfirmationMessage() {
                let message = "Souhaitez-vous continuer ?"
                if (this.selected.length > 0) {
                    message = "Les bénéficiaires suivants seront EXCLUS du déblocage : <br/>" + _.flatMap(this.selected, function (item) {
                        return item.name
                    }).join("<br/>") + "<br/><br/>" + message
                }
                return message;
            }
        }
    }
</script>
