<template>
    <base-layout :withFooter="false" headerSize="108px">
        <template v-slot:header>
            <h1>Fiscalité</h1>
            <v-tabs v-model="currentTab">
                <v-tab key="general">Général</v-tab>
                <v-tab key="tmop">Tmop</v-tab>
                <v-tab key="scic" :disabled="!hasScicExtensionAccess()">Scic</v-tab>
            </v-tabs>
        </template>
        <template v-slot:main>
            <loading-screen ref="loadingComponent"/>
            <v-tabs-items v-model="currentTab">
                <v-tab-item key="general">
                    <base-layout :withHeader="false" :withFooter="false">
                        <template v-slot:main>
                            <v-container>
                                <v-row dense>
                                    <v-col cols="12" sm="6" xl="4" xxl="2">
                                        <datatable-taux-fiscalite
                                            :apiTauxFiscalite="csgCrdsApi"
                                            nameTauxFiscalite="CSG - CRDS"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" xl="4" xxl="2">
                                        <datatable-taux-fiscalite
                                            :apiTauxFiscalite="chargesSocialesApi"
                                            nameTauxFiscalite="charges sociales"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" xl="4" xxl="2">
                                        <datatable-taux-fiscalite
                                            :apiTauxFiscalite="prelevementForfaitaireApi"
                                            nameTauxFiscalite="prélèvement forfaitaire"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" xl="4" xxl="2">
                                        <datatable-taux-fiscalite
                                            :apiTauxFiscalite="forfaitSocialStandardApi"
                                            nameTauxFiscalite="forfait social standard"
                                            canAddTaux
                                            canDeleteTaux
                                            canEditTaux
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" xl="4" xxl="2">
                                        <datatable-taux-fiscalite
                                            :apiTauxFiscalite="forfaitSocialVersementImmedaitApi"
                                            nameTauxFiscalite="forfait social sur versement immédiat"
                                            canAddTaux
                                            canDeleteTaux
                                            canEditTaux
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </base-layout>
                </v-tab-item>
                <v-tab-item key="tmop">
                    <tmop-list/>
                </v-tab-item>
                <v-tab-item key="scic">
                    <v-scic-taux-fiscalite v-if="hasScicExtensionAccess()"/>
                </v-tab-item>
            </v-tabs-items>
        </template>
    </base-layout>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import TmopList from "@/components/tmop/TmopList";
    import VScicTauxFiscalite from "@/components/scic/tauxFiscalite/VScicTauxFiscalite";
    import DatatableTauxFiscalite from "@/components/fiscalite/DatatableTauxFiscalite.vue";
    import authService from "@/services/authService";

    export default {
        name: "TauxFiscalite",
        components: {
            VScicTauxFiscalite,
            BaseLayout,
            loadingScreen,
            TmopList,
            DatatableTauxFiscalite,
        },
        data() {
            return {
                tauxFiscaliteList: undefined,
                tauxCsgCrdsList: undefined,
                tauxChargesSocialesList: undefined,
                tauxPrelevementForfaitaireList: undefined,
                tauxVersementImmedaitList: undefined,
                tauxForfaitStandardList: undefined,
                fiscalites: {
                    date_debut: undefined,
                    date_fin: undefined,
                    taux: undefined,
                },
                headers: [
                    {text: "Date de début", value: "date_debut", sortable: true},
                    {text: "Taux CSG-CRDS", value: "taux_csg_crds", sortable: false, align: "end"},
                    {text: "Taux charges sociales", value: "taux_charges_sociales", sortable: false, align: "end"},
                    {text: "Taux prélèvement forfaitaire", value: "taux_prelevement_forfaitaire", sortable: false, align: "end"},
                    {text: "Forfait social standard", value: "forfait_social_standard", sortable: false, align: "end"},
                    {text: "Forfait social versement immédiat", value: "forfait_social_vers_immediat", sortable: false, align: "end"},
                    {text: "Date de fin", value: "date_fin", sortable: false},
                ],
                pagination: {
                    sortBy: ['date_debut'],
                    sortDesc: [true]
                },
                tauxFiscalitesToCreate: [],
                tauxFiscalitesIdsToDelete: [],
                currentTab: undefined,
                loading: false,
                csgCrdsApi: internalApi.tauxFiscalite.csgCrds,
                chargesSocialesApi: internalApi.tauxFiscalite.chargesSociales,
                prelevementForfaitaireApi: internalApi.tauxFiscalite.prelevementForfaitaire,
                forfaitSocialStandardApi: internalApi.tauxFiscalite.forfaitSocialStandard,
                forfaitSocialVersementImmedaitApi: internalApi.tauxFiscalite.forfaitSocialVersementImmediat,
            }
        },
        mounted() {
        },
        methods: {
            hasScicExtensionAccess() {
                return authService.checkHasScicExtensionAccess();
            },
        },
    }
</script>
