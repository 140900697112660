var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.editTauxFiscalite.apply(null, arguments)}}},[_c('v-card',{staticClass:"elevation-1"},[_c('loading-screen',{ref:"loadingComponent"}),_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm._f("capitalizeFirstLetterFilter")(_vm.nameTauxFiscalite))+" ")]),_c('v-col',{staticClass:"text-end",staticStyle:{"max-height":"32px"},attrs:{"cols":"3"}},[(_vm.canEditTaux)?_c('v-icon-button-with-tool-tip',{attrs:{"detail":"Enregistrer les modifications","icon":'$vuetify.icons.save',"btnIsIcon":"","tooltipTop":"","iconColorClass":dirty ? 'orange' : 'grey darken-1',"disabled":invalid,"type":"submit"}}):_vm._e(),(dirty)?_c('v-icon-button-with-tool-tip',{attrs:{"detail":"Annuler les modifications","icon":'$vuetify.icons.annulation',"btnIsIcon":"","tooltipTop":"","iconColorClass":"grey darken-1"},on:{"click":_vm.resetTauxFiscalite}}):_vm._e(),(!dirty && _vm.canAddTaux)?_c('v-icon-button-with-tool-tip',{attrs:{"detail":_vm.detailCreate,"icon":'$vuetify.icons.add',"btnIsIcon":"","tooltipTop":"","btnColorClass":"primary"},on:{"click":_vm.openModaleCreation}}):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"no-data-text":"Aucun taux de fiscalité","items":_vm.tauxFiscalite,"height":"336px","loading":_vm.loading,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("dateIsoToFrFilter")(item.date_debut)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("dateIsoToFrFilter")(item.date_fin)))]),_c('td',{staticClass:"text-right"},[(item.is_editable && _vm.canEditTaux)?[_c('ValidationProvider',{attrs:{"name":"Taux","vid":'taux' + item.id,"rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{staticClass:"my-0 py-0",attrs:{"prefixValue":"%","reverseValue":true,"errors":errors,"hideDetails":errors.length == 0},model:{value:(item.taux),callback:function ($$v) {_vm.$set(item, "taux", $$v)},expression:"item.taux"}})]}}],null,true)})]:[_vm._v(" "+_vm._s(_vm._f("roundPercentFilter")(item.taux,1, true))+" "),(item.detail_composition)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s('$vuetify.icons.info'))])]}}],null,true)},[_c('table',[_c('tbody',[_vm._l((item.detail_composition),function(detail){return _c('tr',{key:detail.libelle},[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(detail.libelle))]),_c('td',{staticClass:"text-center"},[_c('span',{staticStyle:{"display":"inline-block","width":"30px"}},[_vm._v(_vm._s(detail.operateur))])]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("roundPercentFilter")(detail.taux,1, true)))])])}),_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Taux "+_vm._s(_vm.nameTauxFiscalite))]),_c('td',{staticClass:"text-center"},[_c('span',{staticStyle:{"display":"inline-block","width":"30px"}},[_vm._v("=")])]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("roundPercentFilter")(item.taux,1, true)))])])],2)])]):_vm._e()]],2),(_vm.canDeleteTaux)?_c('td',{staticClass:"text-center"},[_c('v-custom-confirmation-dialog',{attrs:{"disabled":!item.is_editable,"message":"Êtes-vous sûr(e) de vouloir supprimer ce taux de fiscalité ?","isIcon":true,"iconValue":'$vuetify.icons.delete',"tooltipDetailMessage":"Supprimer le taux de fiscalité."},on:{"action-confirmee":function($event){return _vm.deleteTauxFiscalite(item)}}})],1):_vm._e()])]}}],null,true)})],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogCreate),callback:function ($$v) {_vm.dialogCreate=$$v},expression:"dialogCreate"}},[_c('v-card',[_c('ValidationObserver',{ref:"observerCreate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addTauxFiscalite.apply(null, arguments)}}},[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v("Créer un taux de "+_vm._s(_vm.nameTauxFiscalite))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date d'entrée en vigueur","vid":"date_debut","rules":{ required: true , date_after: _vm.dateDebutMin }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":"Date d'entrée en vigueur","errors":errors,"allowedDate":_vm.computeAllowedDates()},model:{value:(_vm.tauxFiscaliteCreate.date_debut),callback:function ($$v) {_vm.$set(_vm.tauxFiscaliteCreate, "date_debut", $$v)},expression:"tauxFiscaliteCreate.date_debut"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Taux","vid":"taux","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-formatted-number-text-field',{attrs:{"labelValue":"Taux","prefixValue":"%","reverseValue":true,"errors":errors},model:{value:(_vm.tauxFiscaliteCreate.taux),callback:function ($$v) {_vm.$set(_vm.tauxFiscaliteCreate, "taux", $$v)},expression:"tauxFiscaliteCreate.taux"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.closeModaleCreation}},[_vm._v("NON, ANNULER")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid,"block":""}},[_vm._v("OUI, CONFIRMER")])],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }