<!-- VUETIFY2 - OK -->
<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                Légende des placeholders
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <template v-if="!loading">
                    <div v-for="(type_specifications, type_name, index) in legends" :value="type_name" :key="index">
                        <chips-legend
                            :typeName="type_name"
                            :typeSpecifications="type_specifications"
                        />
                        <v-divider v-if="index < Object.keys(legends).length - 1" class="my-4"></v-divider>
                    </div>
                </template>
                <v-skeleton-loader v-else type="image, card"></v-skeleton-loader>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                Documentation de la formule de barrette
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <h4>Utilisation des placeholders</h4>
                <p>Choisissez un placeholder (voir légende) et l'entourer avec des accolades (<code>{}</code>).</p>
                <v-alert dense text color="primary" :icon="'$vuetify.icons.punaise'">Exemple :<code>{numero_tiers}</code></v-alert>
                
                <h4>Utilisation des formats</h4>
                <p>Lorsque le nom d'un placeholder est suivi du "pipe" (<code>|</code>), lui-même suivi par un nom de format ; alors le format est appliqué à la valeur lors de la résolution du placeholder.</p>
                <v-alert dense text color="primary" :icon="'$vuetify.icons.punaise'">Exemple :<code>{date_edition | date_to_fr}</code></v-alert>
                
                <h4>Exemple</h4>
                <p>Modèle :</p>
                <v-alert dense text color="primary" :icon="'$vuetify.icons.punaise'">
                    Document adressé à ##<strong>{informal_name}</strong>######<strong>{numero_tiers}</strong>## a été générée le ##<strong>{date_edition | date_to_fr}</strong>##

                </v-alert>
                
                <p>Résultat :</p>
                <v-alert dense text color="primary" :icon="'$vuetify.icons.punaise'">
                    Document adressé à ##<strong>Jean DUPONT</strong>######<strong>12345</strong>## a été générée le ##<strong>11/05/2024</strong>##
                </v-alert>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import internalApi from "@/api/internalApi";
import ChipsLegend from "@/components/formuleBarretteIdentificationPagePdf/ChipsLegend";
import _ from "lodash";
export default {
    name: "ChipsLegends",
    components: {
        ChipsLegend
    },
    data() {
        return {
            placeholdersSpecifications: {},
            formatsSpecifications: {},
            legends: {},
            loading: false,
        }
    },
    mounted() {
        this.fetchBarretteFormatsPlaceholdersDisponibles().then(res => {
            this.computeLegend();
        });
    },
    methods: {
        fetchBarretteFormatsPlaceholdersDisponibles() {
            this.loading = true;
            return internalApi.barretteIdentificationDocumentsPdf.getLabels()
            .then(result => {
                this.placeholdersSpecifications = result.placeholders_disponibles;
                this.formatsSpecifications = result.formats_disponibles;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.loading = false;
            });
        },
        computeLegend() {
            /**
             * Retourne un objet de la forme suivante :
             * { 
             *      "<date>": {
             *          "placeholders": [
             *              {
             *                  "name": "<date_edition>",
             *                  "is_mandatory": <true>,
             *              },
             *          ],
             *          "formats": [
             *              {
             *                  "name": "<date_to_fr>",
             *              },
             *          ]
             *      }
             * }
             */
            const local_legends = {};
            for (const [placeholder_name, placeholder_specifications] of Object.entries(this.placeholdersSpecifications)) {
                local_legends[placeholder_specifications.type] = {
                    "placeholders": [],
                    "formats": [],
                };
            }
            for (const [placeholder_name, placeholder_specifications] of Object.entries(this.placeholdersSpecifications)) {
                local_legends[placeholder_specifications.type].placeholders.push({
                    "name": placeholder_name,
                    "is_mandatory": placeholder_specifications.is_mandatory,
                });
            }
            for (const [type_name, type_specifications] of Object.entries(local_legends)) {
                for (const [format_name, format_specifications] of Object.entries(this.formatsSpecifications)) {
                    if (format_specifications.data_types.includes(type_name)) {
                        type_specifications.formats.push({
                            "name": format_name,
                        });
                    }
                }
            }
            this.legends = local_legends;
        }
    },
}
</script>
