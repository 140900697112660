<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{}">
        <base-layout headerSize="108px" datatableExcludedSizeDefault="157px">
            <template v-slot:header>
                <v-row>
                    <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                        <ValidationProvider vid="anneeFiscale"
                                            name="Année fiscale"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-years-list v-model="anneeFiscale"
                                          label="Année fiscale"
                                          :minYear="2016"
                                          :maxYear="2100"
                                          :errors="errors"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" xl="3" xxl="2">
                        <v-cerfa-list univers="COMMUN"
                                      typeCerfa="IFU_2561"
                                      :year="anneeFiscale"
                                      :correspondingCerfa="correspondingCerfa"
                                      :selectedCerfa="selectedCerfa"
                                      @update-corresponding-cerfa="correspondingCerfa = $event"
                                      @update-selected-cerfa="selectedCerfa = $event"
                        />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:main="{ datatableSize }">
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <v-detail-societe :dateVersionSociete="dateReference"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="dataTiers.headers"
                                :items="dataTiers.items"
                                :server-items-length="dataTiers.totalItems"
                                :no-data-text="noDataTextDataDocumentLegal"
                                :loading="dataTiers.loading"
                                :options.sync="dataTiers.pagination"
                                :footer-props="dataTiers.footerProps"
                                :height="datatableSize"
                                fixed-header
                            >
                                <template v-slot:[`item.personne_type`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon size="25" v-on="on">{{ getIconByPersonneType(item) }}</v-icon>
                                        </template>
                                        <span>{{ getDetailByPersonneType(item) }}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:[`item.libelle_tiers`]="{ item }">
                                    <v-row align="center" justify="space-between" no-gutters>
                                        <v-col>
                                            {{ item.libelle_tiers }}
                                        </v-col>
                                        <v-col cols="1" v-if="item.is_exclu_documents_legaux">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }" class="text-xs-right">
                                                    <v-icon small right v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                                                </template>
                                                <span>Exclu(e) de l'édition des documents légaux</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template v-slot:[`item.date_de_naissance`]="{ item }">
                                    {{ item.date_de_naissance | dateIsoToFrFilter }}
                                </template>
                                <template v-slot:[`item.commune_naissance`]="{ item }">
                                    <template v-if="item.personne_type == 'per_phy'">
                                        {{ item.commune_naissance }}
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </template>
                                <template v-slot:[`item.departement_naissance`]="{ item }">
                                    <template v-if="item.personne_type == 'per_phy'">
                                        <template v-if="item.departement_naissance">
                                            {{ item.departement_naissance }}
                                        </template>
                                        <template v-else>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon size="20" v-on="on" class="orange--text">
                                                        {{ "$vuetify.icons.squareCheckQuestion" }}
                                                    </v-icon>
                                                </template>
                                                <span>Impossible de trouver le code INSEE de la commune de naissance de ce tiers, veuillez vérifier les informations de sa fiche tiers.</span>
                                            </v-tooltip>
                                        </template>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </template>
                                <template v-slot:[`item.interets_parts_sociales_regles`]="{ item }">
                                    {{ item.interets_parts_sociales_regles | roundEuroFilter(0) }}
                                </template>
                                <template v-slot:[`item.produits_placement_revenu_fixe`]="{ item }">
                                    {{ item.produits_placement_revenu_fixe | roundEuroFilter(0) }}
                                </template>
                                <template v-slot:[`item.credit_impot`]="{ item }">
                                    {{ item.credit_impot | roundEuroFilter(0) }}
                                </template>
                                <template v-slot:[`foot`]>
                                    <tfoot>
                                        <tr class="font-weight-bold">
                                            <td colspan="7">Totaux</td>
                                            <template v-if="dataTiersTotaux.loading">
                                                <td><v-skeleton-loader type="text"/></td>
                                                <td><v-skeleton-loader type="text"/></td>
                                                <td><v-skeleton-loader type="text"/></td>
                                            </template>
                                            <template v-else>
                                                <td class="text-right">{{ dataTiersTotaux.interets_parts_sociales_regles | roundEuroFilter(0) }}</td>
                                                <td class="text-right">{{ dataTiersTotaux.produits_placement_revenu_fixe | roundEuroFilter(0) }}</td>
                                                <td class="text-right">{{ dataTiersTotaux.credit_impot | roundEuroFilter(0) }}</td>
                                            </template>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:footer>
                <v-row justify="end" dense>
                    <!-- pop-up -->
                    <v-col cols="12" sm="4" md="3" xl="2" xxl="1">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <dialog-generate-teletransmission-file
                                    openButtonMessage="Générer le fichier de télétransmission"
                                    title="Générer le fichier de télétransmission"
                                    :isIcon="false"
                                    :anneeFiscale="anneeFiscale"
                                    :activator="on"
                                    :disabled="areDownloadsDisabled"
                                    @update-data-teletransmission="downloadFichierTeletransmission($event)"
                                />
                            </template>
                            <span>
                                Le transfert des déclarations annuelles des revenus de capitaux mobiliers
                                peut se faire par transmission d’un fichier TD Bilatéral (CERFA 2561).
                            </span>
                        </v-tooltip>
                    </v-col>
                    <!-- Grand bouton -->
                    <v-col cols="12" sm="4" md="3" xl="2" xxl="1">
                        <v-btn color="primary" @click="downloadCerfa2561" block :disabled="areDownloadsDisabled">
                            <v-row justify="space-between" align="center" no-gutters>
                                <v-col cols="1">
                                    <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                                </v-col>
                                <v-col>
                                    Générer le CERFA&nbsp;2561
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-col>
                    <!-- Grand bouton -->
                    <v-col cols="12" sm="4" md="3" xl="2" xxl="1">
                        <v-btn color="primary" @click="downloadCerfa2561Ter" block :disabled="areDownloadsDisabled">
                            <v-row justify="space-between" align="center" no-gutters>
                                <v-col cols="1">
                                    <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                                </v-col>
                                <v-col>
                                    Générer le CERFA&nbsp;2561&nbsp;ter
                                </v-col>
                            </v-row>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </base-layout>
        <loading-screen ref="loadingComponent"/>
    </ValidationObserver>
</template>

<script>
    import internalApi from "@/api/internalApi";
    import Utils from "@/utils";
    import BaseLayout from "@/components/BaseLayout";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import VCerfaList from "@/components/fiscalite/VCerfaList";
    import VDetailSociete from "@/components/VDetailSociete.vue"
    import DialogGenerateTeletransmissionFile from "@/components/DialogGenerateTeletransmissionFile.vue";
    import VYearsList from "@/components/VYearsList";
    import _ from "lodash";

    export default {
        components: {
            BaseLayout,
            loadingScreen,
            VCerfaList,
            VDetailSociete,
            DialogGenerateTeletransmissionFile,
            VYearsList,
        },
        data() {
            return {
                anneeFiscale: null,
                correspondingCerfa: null,
                selectedCerfa: null,
                loadingMsgIFU: "L’édition des IFU de la société nécessite un traitement susceptible de durer plusieurs minutes.",
                dataTiersTotaux: {
                    interets_parts_sociales_regles: null,
                    produits_placement_revenu_fixe: null,
                    credit_impot: null,
                    loading: false,
                },
                dataTiers: {
                    headers: [
                        {
                            text: "",
                            value: "personne_type",
                            sortable: false,
                            align: "left",
                            width: "5%"
                        },
                        {
                            text: "Tiers",
                            value: "libelle_tiers",
                            sortable: false,
                            align: "left",
                            width: "10%"
                        },
                        {
                            text: "Numéro de tiers",
                            value: "numero_tiers",
                            sortable: false,
                            align: "left",
                            width: "10%"
                        },
                        {
                            text: "Adresse",
                            value: "adresse",
                            sortable: false,
                            align: "left",
                            width: "20%"
                        },
                        {
                            text: "Date de naissance",
                            value: "date_de_naissance",
                            sortable: false,
                            align: "center",
                            width: "10%"
                        },
                        {
                            text: "Commune de naissance",
                            value: "commune_naissance",
                            sortable: false,
                            align: "left",
                            width: "10%"
                        },
                        {
                            text: "Département de naissance",
                            value: "departement_naissance",
                            sortable: false,
                            align: "left",
                            width: "5%"
                        },
                        {
                            text: "Intérêts aux parts sociales réglés",
                            value: "interets_parts_sociales_regles",
                            sortable: false,
                            align: "right",
                            width: "10%"
                        },
                        {
                            text: "Produits de placement à revenu fixe (2TR)",
                            value: "produits_placement_revenu_fixe",
                            sortable: false,
                            align: "right",
                            width: "10%"
                        },
                        {
                            text: "Crédit d'impôt prélèvement (2CK)",
                            value: "credit_impot",
                            sortable: false,
                            align: "right",
                            width: "10%"
                        },
                    ],
                    totalItems: 0,
                    items: [],
                    pagination: {
                        page: 1,
                        itemsPerPage: 10,
                        sortBy: ["libelle_tiers"],
                        sortDesc: [true]
                    },
                    footerProps: {
                        itemsPerPageOptions: [
                            10,
                            20,
                            50,
                        ],
                    },
                    loading: false,
                },
            }
        },
        mounted() {

        },
        computed: {
            dateReference() {
                if (_.isNil(this.anneeFiscale)) {
                    return null;
                }
                return `${this.anneeFiscale}-12-31`;
            },
            noDataTextDataDocumentLegal() {
                if (_.isNil(this.anneeFiscale)) {
                    return "Sélectionner une année fiscale";
                } else {
                    return "Aucun tiers n'est éligible à la génération de ce document";
                }
            },
            areDownloadsDisabled() {
                return _.isNil(this.anneeFiscale);
            },
        },
        watch: {
            anneeFiscale: function() {
                this.dataTiers.pagination.page = 1;
                this.fetchPaginatedDataTiers();
                this.fetchDataTiersTotaux();
            },
            'dataTiers.pagination': {
                handler() {
                    this.fetchPaginatedDataTiers();
                },
                deep: true
            },
        },
        methods: {
            fetchDataTiersTotaux() {
                if (!_.isNil(this.anneeFiscale)) {
                    this.dataTiersTotaux.loading = true;
                    internalApi.documentsLegaux.cerfa2561.getDataTiersTotauxFromAnneeFiscale(
                        this.anneeFiscale,
                    )
                    .then(response => {
                        this.dataTiersTotaux.interets_parts_sociales_regles = response.interets_parts_sociales_regles;
                        this.dataTiersTotaux.produits_placement_revenu_fixe = response.produits_placement_revenu_fixe;
                        this.dataTiersTotaux.credit_impot = response.credit_impot;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.dataTiersTotaux.loading = false;
                    });
                } else {
                    this.dataTiersTotaux.interets_parts_sociales_regles = null;
                    this.dataTiersTotaux.produits_placement_revenu_fixe = null;
                    this.dataTiersTotaux.credit_impot = null;
                }
            },
            fetchPaginatedDataTiers() {
                if (!_.isNil(this.anneeFiscale)) {
                    const {sortBy, sortDesc, page, itemsPerPage} = this.dataTiers.pagination;

                    this.dataTiers.loading = true;
                    internalApi.documentsLegaux.cerfa2561.getDataTiersFromAnneeFiscale(
                        this.anneeFiscale,
                        {
                            page: page,
                            rows_per_page: itemsPerPage,
                            sort_key: sortBy[0],
                            descending: sortDesc[0],
                        },
                    )
                    .then(response => {
                        this.dataTiers.items = response.items;
                        this.dataTiers.totalItems = response.total_items;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .finally(() => {
                        this.dataTiers.loading = false;
                    });
                } else {
                    this.dataTiers.items = [];
                    this.dataTiers.totalItems = 0;
                }
            },
            downloadCerfa2561(){
                this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
                let params = {
                    "version_cerfa": !_.isNil(this.selectedCerfa) ? this.selectedCerfa["name"] : null,
                }
                return internalApi.documentsLegaux.cerfa2561.downloadCerfa2561(this.anneeFiscale, params)
                    .then(response => {
                        Utils.downloadFile(response, `cerfa_2561_${this.anneeFiscale}.pdf`, Utils.pdfMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            downloadCerfa2561Ter(){
                this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
                let params = {
                    "version_cerfa": !_.isNil(this.selectedCerfa) ? this.selectedCerfa["ter"] : null,
                }
                return internalApi.documentsLegaux.cerfa2561.downloadCerfa2561ter(this.anneeFiscale, params)
                    .then(response => {
                        Utils.downloadFile(response, `cerfa_2561_ter_${this.anneeFiscale}.pdf`, Utils.pdfMimetype);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            downloadFichierTeletransmission(teletransmissionData){
                this.$refs.loadingComponent.setLoading(this.loadingMsgIFU);
                let params = {
                    "annee_teletransmission": teletransmissionData["annee_teletransmission"],
                    "type_fichier": teletransmissionData["type_fichier"],
                    "version_fichier": teletransmissionData["version_fichier"],
                    "type_clef_gnupg": teletransmissionData["type_clef_gnupg"],
                }
                return internalApi.documentsLegaux.cerfa2561.downloadFichierTeletransmission(this.anneeFiscale, params)
                    .then(response => {
                        Utils.downloadFile(response.data_fichier, response.nom_fichier, response.content_type);
                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
            },
            /**
             * Renvoie l'icône correspondant au type de la personne
             * @param personne
             * @returns {string}
             */
            getIconByPersonneType(personne) {
                return personne.personne_type === "per_phy" ? "fa-user" : "fa-university";
            },
            /**
             * Renvoie la description correspondante au type de la personne
             * @param personne
             * @returns {string}
             */
            getDetailByPersonneType(personne) {
                return personne.personne_type === "per_phy" ? "Personne physique" : "Personne morale";
            },
        },
    }
</script>
