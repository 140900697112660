import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default cerfaApi;

function cerfaApi() {
    const specificApi = {
        getCorrespondingCerfaVersion: getCorrespondingCerfaVersion(),
    };
    return Object.assign(specificApi, defaultApi(internalApiRoutes.cerfa.baseUrl, [ACCEPTED_METHODS.getLabels]));
}

function getCorrespondingCerfaVersion() {
  function correspondingCerfaVersion(exerciceId, univers, typeCerfa, year, month, queryParams, headers) {
    let path = internalApiRoutes.cerfa.getCorrespondingCerfaVersion();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.exercice_id = exerciceId;
    queryParams.univers = univers;
    queryParams.type_cerfa = typeCerfa;
    queryParams.year = year;
    queryParams.month = month;
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return correspondingCerfaVersion;
}