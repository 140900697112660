var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('base-layout',{attrs:{"headerSize":"108px","datatableExcludedSizeDefault":"157px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3","xxl":"2"}},[_c('ValidationProvider',{attrs:{"vid":"anneeFiscale","name":"Année fiscale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-years-list',{attrs:{"label":"Année fiscale","minYear":2016,"maxYear":2100,"errors":errors},model:{value:(_vm.anneeFiscale),callback:function ($$v) {_vm.anneeFiscale=$$v},expression:"anneeFiscale"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3","xxl":"2"}},[_c('v-cerfa-list',{attrs:{"univers":"COMMUN","typeCerfa":"IFU_2561","year":_vm.anneeFiscale,"correspondingCerfa":_vm.correspondingCerfa,"selectedCerfa":_vm.selectedCerfa},on:{"update-corresponding-cerfa":function($event){_vm.correspondingCerfa = $event},"update-selected-cerfa":function($event){_vm.selectedCerfa = $event}}})],1)],1)]},proxy:true},{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-detail-societe',{attrs:{"dateVersionSociete":_vm.dateReference}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.dataTiers.headers,"items":_vm.dataTiers.items,"server-items-length":_vm.dataTiers.totalItems,"no-data-text":_vm.noDataTextDataDocumentLegal,"loading":_vm.dataTiers.loading,"options":_vm.dataTiers.pagination,"footer-props":_vm.dataTiers.footerProps,"height":datatableSize,"fixed-header":""},on:{"update:options":function($event){return _vm.$set(_vm.dataTiers, "pagination", $event)}},scopedSlots:_vm._u([{key:"item.personne_type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"25"}},on),[_vm._v(_vm._s(_vm.getIconByPersonneType(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDetailByPersonneType(item)))])])]}},{key:"item.libelle_tiers",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(item.libelle_tiers)+" ")]),(item.is_exclu_documents_legaux)?_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',[_vm._v("Exclu(e) de l'édition des documents légaux")])])],1):_vm._e()],1)]}},{key:"item.date_de_naissance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateIsoToFrFilter")(item.date_de_naissance))+" ")]}},{key:"item.commune_naissance",fn:function(ref){
var item = ref.item;
return [(item.personne_type == 'per_phy')?[_vm._v(" "+_vm._s(item.commune_naissance)+" ")]:[_vm._v(" - ")]]}},{key:"item.departement_naissance",fn:function(ref){
var item = ref.item;
return [(item.personne_type == 'per_phy')?[(item.departement_naissance)?[_vm._v(" "+_vm._s(item.departement_naissance)+" ")]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"orange--text",attrs:{"size":"20"}},on),[_vm._v(" "+_vm._s("$vuetify.icons.squareCheckQuestion")+" ")])]}}],null,true)},[_c('span',[_vm._v("Impossible de trouver le code INSEE de la commune de naissance de ce tiers, veuillez vérifier les informations de sa fiche tiers.")])])]]:[_vm._v(" - ")]]}},{key:"item.interets_parts_sociales_regles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roundEuroFilter")(item.interets_parts_sociales_regles,0))+" ")]}},{key:"item.produits_placement_revenu_fixe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roundEuroFilter")(item.produits_placement_revenu_fixe,0))+" ")]}},{key:"item.credit_impot",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("roundEuroFilter")(item.credit_impot,0))+" ")]}},{key:"foot",fn:function(){return [_c('tfoot',[_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Totaux")]),(_vm.dataTiersTotaux.loading)?[_c('td',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1),_c('td',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1),_c('td',[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)]:[_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(_vm.dataTiersTotaux.interets_parts_sociales_regles,0)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(_vm.dataTiersTotaux.produits_placement_revenu_fixe,0)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("roundEuroFilter")(_vm.dataTiersTotaux.credit_impot,0)))])]],2)])]},proxy:true}],null,true)})],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"justify":"end","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2","xxl":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('dialog-generate-teletransmission-file',{attrs:{"openButtonMessage":"Générer le fichier de télétransmission","title":"Générer le fichier de télétransmission","isIcon":false,"anneeFiscale":_vm.anneeFiscale,"activator":on,"disabled":_vm.areDownloadsDisabled},on:{"update-data-teletransmission":function($event){return _vm.downloadFichierTeletransmission($event)}}})]}}],null,true)},[_c('span',[_vm._v(" Le transfert des déclarations annuelles des revenus de capitaux mobiliers peut se faire par transmission d’un fichier TD Bilatéral (CERFA 2561). ")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2","xxl":"1"}},[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.areDownloadsDisabled},on:{"click":_vm.downloadCerfa2561}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.pdf"))])],1),_c('v-col',[_vm._v(" Générer le CERFA 2561 ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","xl":"2","xxl":"1"}},[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.areDownloadsDisabled},on:{"click":_vm.downloadCerfa2561Ter}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.pdf"))])],1),_c('v-col',[_vm._v(" Générer le CERFA 2561 ter ")])],1)],1)],1)],1)]},proxy:true}],null,true)}),_c('loading-screen',{ref:"loadingComponent"})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }