import defaultApi, {ACCEPTED_METHODS} from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";
import {apiConstructor} from "@/api";


export default scicReductionImpotsApi;

function scicReductionImpotsApi()  {
  const specificApi = {
    getValueAtDate: getValueAtDate(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.scicTauxFiscalite.scicReductionImpots.baseUrl,
    [
      ACCEPTED_METHODS.getAll,
      ACCEPTED_METHODS.postAll,
      ACCEPTED_METHODS.putAll,
      ACCEPTED_METHODS.deleteById,
    ],
  ));
}

function getValueAtDate() {
  function getValueAtDate(dateString, queryParams, headers) {
    let path = internalApiRoutes.scicTauxFiscalite.scicReductionImpots.getValueAtDate(dateString);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }
  return getValueAtDate;
}
