<!-- VUETIFY2 - OK -->
<template>
    <v-autocomplete :value="value"
                    @input="$emit('input', $event)"
                    :label="label"
                    :items="localYearsList"
                    :error-messages="errors"
                    :disabled="isDisabled"/>
</template>

<script>
    import _ from "lodash";

    /**
     * Composant affichant une liste déroulante d'une plage d'années
     * @displayName Commun - Liste des années
     */
    export default {
        name: "v-years-list",
        props: {
            /**
             * Année à présélectionner
             */
            value: {
                type: [Object, Number]
            },
            /**
             * Année à présélectionner
             */
            label: {
                type: String,
                required: false,
                default: "Année",
            },
            /**
             * Si vrai, la liste est désactivée, si faux, la liste est activée
             */
            isDisabled: Boolean,
            /**
             * L'année la plus "petite", ancienne, à afficher
             */
            minYear: {
                type: Number,
                default: 2020,
            },
            /**
             * L'année la plus "grande", récente, à afficher
             */
            maxYear: {
                type: Number,
                default: 2040,
            },
            errors: {
                type: Array,
                required: false
            },
        },
        mounted() {
            this.localYearsList = this.computeYearsList();

        },
        data() {
            return {
                localYearsList: [],
            }
        },
        methods: {
            computeYearsList() {
                const yearsList = _.range(this.minYear, this.maxYear + 1);
                var finalList = [];
                yearsList.forEach(year => {
                    finalList.push({text: year, value: year});
                });
                return finalList;
            }
        }
    };
</script>


