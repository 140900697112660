<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver ref="formFiscalite" v-slot="{ validate, errors }">
        <base-layout :withFooter="false" headerSize="130px">
            <template v-slot:header>
                <loading-screen ref="loadingComponent"/>
                <v-row>
                    <v-col md="3" xl="2" v-if="selectableExercice">
                        <ValidationProvider vid="selectedExercice" name="Exercice"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-exercices-list v-model="selectedExercice"
                                              :errors="errors"
                                              ref="exercices"/>
                        </ValidationProvider>
                    </v-col>
                    <v-col md="3" xl="2" v-if="selectableYear">
                        <ValidationProvider vid="selectedYear" name="Année"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-years-list v-model="selectedYear"
                                          :errors="errors"
                                          ref="years"/>
                        </ValidationProvider>
                    </v-col>
                    <v-col md="3" xl="2" v-if="selectableMonth">
                        <ValidationProvider vid="selectedMonth" name="Mois"
                                            rules="required"
                                            v-slot="{ errors }">
                            <v-months-list v-model="selectedMonth"
                                           :error-messages="errors"
                                           ref="months"/>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-tabs v-model="currentTab">
                    <v-tab key="fiscalite">Cerfa 2777</v-tab>
                    <v-tab v-if="univers==='CAPITAL'" key="listeminist">Liste ministérielle</v-tab>
                    <v-tab v-if="univers==='CAPITAL'" :disabled="!hasScicExtensionAccess()" key="fiscaliteScic">Scic</v-tab>
                </v-tabs>
            </template>
            <template v-slot:main>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item key="fiscalite">
                        <base-layout :withHeader="false" :withFooter="false">
                            <template v-slot:main>
                                <v-container fluid class="my-0 py-0">
                                    <v-row class="mt-0">
                                        <v-col cols="12" md="3" xl="2" xxl="1" class="py-0">
                                            <v-cerfa-list :exerciceId="selectedExercice"
                                                        :univers="univers"
                                                        :typeCerfa="checkedValue"
                                                        :year="selectedYear"
                                                        :month="selectedMonth"
                                                        :correspondingCerfa="correspondingCerfa"
                                                        :selectedCerfa="selectedCerfa"
                                                        @update-corresponding-cerfa="correspondingCerfa = $event"
                                                        @update-selected-cerfa="selectedCerfa = $event"/>
                                            <p v-if="correspondingCerfa === undefined">
                                                Veuillez saisir toutes les informations demandées avant de télécharger un cerfa ({{ mandatoryInfoCerfaList.join(', ') }})
                                            </p>
                                        </v-col>
                                        <v-col cols="12" md="9" xl="10" xxl="11" class="py-0" v-if="univers === 'CAPITAL'">
                                            <v-pfu-capital v-if="checkedValue === 'PFU_2777_capital'"
                                                           :univers="univers"
                                                           :exerciceId="selectedExercice"
                                                           :year="selectedYear"
                                                           :month="selectedMonth"
                                                           :selectedCerfa="selectedCerfa"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="9" xl="10" xxl="11" class="py-0" v-else-if="univers === 'PARTICIPATION'">
                                            <v-pfu-participation v-if="checkedValue === 'PFU_2777_participation'"
                                                                 :univers="univers"
                                                                 @update-selectable-exercice="selectableExercice = $event"
                                                                 @update-selectable-year="selectableYear = $event"
                                                                 @update-selectable-month="selectableMonth = $event"
                                                                 :exerciceId="selectedExercice"
                                                                 :year="selectedYear"
                                                                 :month="selectedMonth"
                                                                 :selectedCerfa="selectedCerfa"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                        </base-layout>
                    </v-tab-item>
                    <v-tab-item v-if="univers==='CAPITAL'" key="livretminist">
                        <base-layout :withHeader="false" :withFooter="false">
                            <template v-slot:main>
                                <v-row no-gutters class="pl-3">
                                    <!-- Grand bouton -->
                                    <v-col md="4" lg="4" xl="3">
                                        <v-btn color="primary" @click="downloadListeMinisterielle" block>
                                            <v-icon left dark>{{ "$vuetify.icons.excel" }}
                                            </v-icon>
                                            Générer la liste ministérielle
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </v-tab-item>
                    <v-tab-item v-if="univers==='CAPITAL'" key="fiscaliteScic">
                        <base-layout :withHeader="false" :withFooter="false">
                            <template v-slot:main>
                                <VScicFiscalite :selectedExercice="selectedExercice" :errorsList="errors"
                                                :validate-form="validate"/>
                            </template>
                        </base-layout>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
import VPfuCapital from "@/components/fiscalite/VPfuCapital";
import VPfuParticipation from "@/components/fiscalite/VPfuParticipation";
import VCerfaList from "@/components/fiscalite/VCerfaList";
import VExercicesList from "@/components/VExercicesList";
import VYearsList from "@/components/VYearsList";
import VMonthsList from "@/components/VMonthsList";
import loadingScreen from "@/components/LoadingScreen.vue";
import BaseLayout from "@/components/BaseLayout";
import authService from "@/services/authService";
import VScicFiscalite from "@/views/scic/VScicFiscalite";
import _ from "lodash";

/**
 * Composant affichant les données relatives aux documents légaux
 * @displayName Commun - Fiscalité
 */
export default {
    name: "fiscalite",
    components: {
        VScicFiscalite,
        VPfuCapital,
        VPfuParticipation,
        VCerfaList,
        VExercicesList,
        VYearsList,
        VMonthsList,
        loadingScreen,
        BaseLayout
    },
    data() {
        return {
            currentTab: undefined,
            checkedValue: undefined,
            selectableExercice: undefined,
            selectableYear: undefined,
            selectableMonth: undefined,
            selectedExercice: null,
            selectedYear: null,
            selectedMonth: null,
            univers: undefined, // Surchargé dans FiscaliteCapital et FiscaliteParticipation
            correspondingCerfa: undefined,
            selectedCerfa: undefined,
        }
    },
    methods: {
        loadTab() {
            this.$refs.formFiscalite.validate();
        },
        hasScicExtensionAccess() {
            return authService.checkHasScicExtensionAccess();
        },
    },
    computed: {
        mandatoryInfoCerfaList() {
            let tmpList = [];
            if (this.univers === "CAPITAL"){
                tmpList.push("exercice");
            }else if (this.univers === "PARTICIPATION"){
                tmpList.push("année");
                if (this.checkedValue === "PFU_2777_participation"){
                    tmpList.push("mois");
                }
            }
            if (this.checkedValue === undefined){
                tmpList.push("type de document");
            }
            return tmpList;
        }
    }
}

</script>