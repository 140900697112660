import _ from "lodash";
import Utils from "@/utils.js";
import {apiConstructor} from "@/api";
import {TOAST} from "@/plugins/toast/definition";
import TOAST_MESSAGE from "@/plugins/toast/messages";

const SLASH = "/";
const LABELS = "labels";

/**
 * Object containing all methods you MUST add to default api object
 */
export const ACCEPTED_METHODS = {
  getLabels: "getLabels",
  getOnlyOne: "getOnlyOne",
  getAll: "getAll",
  postAll: "postAll",
  postOnlyOne: "postOnlyOne",
  getById: "getById",
  putOnlyOne: "putOnlyOne",
  putById: "putById",
  putAll: "putAll",
  deleteById: "deleteById"
};

export default defaultApi;

/**
 *  Create default api module for the path in parameters
 *  containing all method in parameter listMethod.
 *  If method list is empty, it fills API with ALL methods
 *
 *  List of accepted method is contained by ACCEPTED_METHODS variable
 *
 * @param {String} path api route to use for this API
 * @param {Array} listMethod list of method to add to the API
 */
function defaultApi(path, listMethod = []) {
  if (!path.startsWith(SLASH)) {
    path = SLASH + path;
  }

  let apiObject = {};

  if (_.isEmpty(listMethod)) {
    listMethod = Object.values(ACCEPTED_METHODS);
  }

  for (let method of listMethod) {
    apiObject = addMethodToObjectApi(apiObject, method, path);
  }

  return apiObject;
}

/**
 * Fill API Object with method in params as a key
 * and the api contructor for this method and this path as a value
 * @see apiConstructor in api/index.js
 *
 * @param {Object} apiObjectToFill object to fill with method
 * @param {String} method to add to api object as property
 * @param {String} path used to set property value for the method defined in api object
 *
 * @returns {Object} the newly filled apiObjectToFill
 */
function addMethodToObjectApi(apiObjectToFill, method, path) {
  switch (method) {
    case ACCEPTED_METHODS.getLabels:
      apiObjectToFill.getLabels = apiConstructor.get(path + SLASH + LABELS);
      break;
    case ACCEPTED_METHODS.getOnlyOne:
      apiObjectToFill.getOnlyOne = apiConstructor.get(path);
      break;
    case ACCEPTED_METHODS.getAll:
      apiObjectToFill.getAll = apiConstructor.get(path);
      break;
    case ACCEPTED_METHODS.postAll:
      apiObjectToFill.postAll = apiConstructor.post(path);
      apiObjectToFill.postAllWithToastConfirmation = postAllWithToast(path);
      apiObjectToFill.postAllWithToastAsynchronous = postAllWithToastAsynchronous(path);
      break;
    case ACCEPTED_METHODS.getById:
      apiObjectToFill.getById = getById(path);
      break;
    case ACCEPTED_METHODS.putById:
      apiObjectToFill.putById = putById(path);
      apiObjectToFill.putByIdWithToastConfirmation = putByIdWithToast(path);
      break;
    case ACCEPTED_METHODS.putOnlyOne:
      apiObjectToFill.putOnlyOne = apiConstructor.put(path);
      apiObjectToFill.putOnlyOneWithToastConfirmation = putAllWithToast(path);
      apiObjectToFill.putOnlyOneWithToastAsynchronous = putAllWithToastAsynchronous(path);
      break;
    case ACCEPTED_METHODS.putAll:
      apiObjectToFill.putAll = apiConstructor.put(path);
      apiObjectToFill.putAllWithToastConfirmation = putAllWithToast(path);
      apiObjectToFill.putAllWithToastAsynchronous = putAllWithToastAsynchronous(path);
      break;
    case ACCEPTED_METHODS.deleteById:
      apiObjectToFill.deleteByIdWithToastConfirmation = deleteByIdWithToast(path);
      break;
  }
  return apiObjectToFill;
}

/**
 * Call API with POST method on path parameter and display a generic toast on success.
 * For a specific toast message use postAll and call $toast on success in vue file.
 *
 * @param {String} path api route to call
 */
function postAllWithToast(path) {
  function apiConstructorPostAll(body, queryParams, headers) {
    let postAllForPath = apiConstructor.post(path);
    return postAllForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.post.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPostAll;
}


/**
 * Call API with POST method for asynchronous treatment on path parameter and display a generic toast on success.
 * For a specific toast message use postAll and call $toast on success in vue file.
 *
 * @param {String} path api route to call
 */
function postAllWithToastAsynchronous(path) {
  function apiConstructorPostAll(body, queryParams, headers) {
    let postAllForPath = apiConstructor.post(path);
    return postAllForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.simpleLaunch, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPostAll;
}

/**
 * Call API with PUT method on path parameter and display a generic toast on success.
 * For a specific toast message use putAll and call $toast on success in vue file.
 *
 * @param {String} path api route to call
 */
function putAllWithToast(path) {
  function apiConstructorPutAll(body, queryParams, headers) {
    let putAllForPath = apiConstructor.put(path);
    return putAllForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPutAll;
}


/**
 * Call API with PUT method for asynchronous treatment on path parameter and display a generic toast on success.
 * For a specific toast message use putAll and call $toast on success in vue file.
 *
 * @param {String} path api route to call
 */
function putAllWithToastAsynchronous(path) {
  function apiConstructorPutAll(body, queryParams, headers) {
    let putAllForPath = apiConstructor.put(path);
    return putAllForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.asynchrone.simpleLaunch, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPutAll;
}

/**
 * Call API with GET method on path parameter.
 *
 * @param {String} path api route to call
 */
function getById(path) {
  function apiConstructorGetById(id, queryParams, headers) {
    let getForPath = apiConstructor.get(path + SLASH + id);
    return getForPath(queryParams, headers);
  }

  return apiConstructorGetById;
}

/**
 * Call API with PUT method on path parameter.
 *
 * @param {String} path api route to call
 */
function putById(path) {
  function apiConstructorPutById(id, body, queryParams, headers) {
    let putForPath = apiConstructor.put(path + SLASH + id);
    return putForPath(body, queryParams, headers);
  }

  return apiConstructorPutById;
}

/**
 * Call API with PUT method on path parameter and display a generic toast on success.
 * For a specific toast message use postAll and call $toast on success in vue file.
 *
 * @param {String} path api route to call
 */
function putByIdWithToast(path) {
  function apiConstructorPutById(id, body, queryParams, headers) {
    let putForPath = apiConstructor.put(path + SLASH + id);
    return putForPath(body, queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.put.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPutById;
}

/**
 * Call API with DELETE method on path parameter and display a generic toast on success.
 *
 * @param {String} path api route to call
 */
function deleteByIdWithToast(path) {
  function apiConstructorPutById(id, body, queryParams, headers) {
    let deleteForPath = apiConstructor.delete(path + SLASH + id);
    return deleteForPath(queryParams, headers).then(result => {
      TOAST.success(TOAST_MESSAGE.success.delete.defaultTitle, Utils.EMPTY_STRING);
      return Promise.resolve(result);
    });
  }

  return apiConstructorPutById;
}