<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false" :withFooter="false" datatableExcludedSizeDefault="200px">
        <template v-slot:main="{}">
            <loading-screen ref="loadingComponent"/>
            <v-expansion-panels v-model="expansionPanels" inset multiple>

                <v-expansion-panel v-if="areDocumentsPersonnels">
                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                        <h3>Commun</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row v-if="areDocumentsPersonnels">
                            <v-col cols="12" md="6" lg="5" xl="3">
                                <download-cerfa-2561-ter-individuel/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="hasAccessCapital()">
                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                        <h3>Capital</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col>
                                <v-datatable-docs-list
                                    :documentsList="docsCapital"
                                    @new-uploaded-doc="loadPersonalDocs(typeDocumentCapital).then(res => {docsCapital = res})"
                                    :collaborateurId="collaborateurId"
                                    @on-delete-document="loadPersonalDocs(typeDocumentCapital).then(res => {docsCapital = res})"
                                    :typeDocument="typeDocumentCapital"
                                    :canDelete="canDelete"
                                    :canUpload="canUpload"
                                    :hasAccess="hasAccessCapital()"
                                    :areDocumentsPersonnels="areDocumentsPersonnels"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="areDocumentsPersonnels">
                            <v-col cols="12" md="6" lg="5" xl="3">
                                <v-etat-du-capital-individuel/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="hasAccessParticipation()">
                    <v-expansion-panel-header :expand-icon="'$vuetify.icons.chevronDown'">
                        <h3>Participation</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col>
                                <v-datatable-docs-list
                                    :documentsList="docsParticipation"
                                    @new-uploaded-doc="loadPersonalDocs(typeDocumentParticipation).then(res => {docsParticipation = res})"
                                    :collaborateurId="collaborateurId"
                                    @on-delete-document="loadPersonalDocs(typeDocumentParticipation).then(res => {docsParticipation = res})"
                                    :typeDocument="typeDocumentParticipation"
                                    :canDelete="canDelete"
                                    :canUpload="canUpload"
                                    :hasAccess="hasAccessParticipation()"
                                    :areDocumentsPersonnels="areDocumentsPersonnels"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="areDocumentsPersonnels">
                            <v-col cols="12" md="6" lg="5" xl="3">
                                <v-livret-epargne-salariale/>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </template>
    </base-layout>
</template>
<script>
    import authService from "@/services/authService";
    import VDatatableDocsList from "@/views/documents/VDatatableDocsList.vue"
    import internalApi from "@/api/internalApi";
    import BaseLayout from "@/components/BaseLayout";
    import DownloadCerfa2561TerIndividuel from "@/components/DownloadCerfa2561TerIndividuel";
    import VEtatDuCapitalIndividuel from "@/components/VEtatDuCapitalIndividuel";
    import VLivretEpargneSalariale from "@/components/VLivretEpargneSalariale";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";

    /**
     * Composant affichant deux datatables contenant les documents du tiers
     * @displayName Commun - VDocumentsList
     */
    export default {

        components: {
            BaseLayout,
            VDatatableDocsList,
            DownloadCerfa2561TerIndividuel,
            VEtatDuCapitalIndividuel,
            VLivretEpargneSalariale,
            loadingScreen
        },
        props: {
            /**
             * Autorise ou non la suppression de documents
             */
            canDelete: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * Autorise ou non l'upload de documents
             */
            canUpload: {
                type: Boolean,
                required: false,
                default: true
            },
            /**
             * L'identifiant du collaborateur à qui appartient les documents, requis si areDocumentsPersonnels == false
             */
            collaborateurId: {
                required: false,
                default: 0,
            },
            /**
             * Si les documents sont ceux de la personne actuellement connectée,
             * collaborateurId devra être setté si areDocumentsPersonnels == false
             */
            areDocumentsPersonnels: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                formData: new FormData(),
                docsCapital: [],
                docsParticipation: [],
                typeDocumentParticipation: "Pb",
                typeDocumentCapital: "Cap",
                expansionPanels: [0],
            }
        },
        watch: {
            collaborateurId() {
                this.initDocs();
            }
        },
        mounted() {
            this.initDocs();
        },
        methods: {
            /**
             * Récupère les documents du collaborateur via des promesses
             */
            initDocs() {

                if (!_.isNil(this.collaborateurId) && this.collaborateurId != "null") {

                    this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
                    var all_promises = []

                    if(this.hasAccessCapital()){
                        const docsBulletin = this.loadPersonalDocs(this.typeDocumentCapital).then(res => {
                            this.docsCapital = res;
                            return Promise.resolve(res)
                        });
                        all_promises.push(docsBulletin);
                    } else {
                        this.docsCapital = [];
                    }

                    if(this.hasAccessParticipation()){
                        const docAutre = this.loadPersonalDocs(this.typeDocumentParticipation).then(res => {
                            this.docsParticipation = res;
                            return Promise.resolve(res)
                        });
                        all_promises.push(docAutre);
                    } else {
                        this.docsParticipation = [];
                    }

                    Promise.all(all_promises).then(results => {

                    }).catch(err => {
                        console.error(err);
                    }).finally(() => {
                        this.$refs.loadingComponent.resetLoading();
                    });
                }
            },
            /**
             * Récupère pour un collaborateur l'ensemble des documents correspondants
             * au type passé en paramètre
             * @param typeDoc le type de document désiré
             * @returns {[object]}
             */
            loadPersonalDocs: function (typeDoc) {
                let promise;
                if (this.areDocumentsPersonnels) {
                    promise = internalApi.documents.getAllPersonnel(typeDoc);
                } else {
                    promise = internalApi.documents.getAll(this.collaborateurId, typeDoc);
                }

                return promise.then(res => {
                    return Promise.resolve(res);
                });
            },
            hasAccessCapital: function () {
                return authService.hasAccessCapital();
            },
            hasAccessParticipation: function () {
                return authService.hasAccessParticipation();
            }

        }
    }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1, h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
