<!-- VUETIFY2 - OK -->
<template>
    <base-layout headerSize="220px">
        <template v-slot:header>
            <!-- Le titre n'est pas dans le header, car cela masquerait le menu -->
            <h1 class="pl-3">Dettes</h1>
            <loading-screen ref="loadingComponent"/>
            <br/>
            <v-row dense>
                <v-col cols="3" class="ml-5">
                        <v-text-field v-model="search"
                                      append-icon="search"
                                      label="Nom du tiers"
                                      single-line
                                      @input="onSearchChange()"/>
                </v-col>
                <v-col cols="3" class="ml-10">
                    <v-select v-model="selectedTypeDette" @change="filterDettes(selectedExerciceId)"
                                        :items="switchTypeDette" item-text="label"
                                        item-value="key" label="Type de dettes à afficher"/>
                </v-col>
                <v-col cols="3" class="ml-10">
                    <v-select v-model="selectedSepaGenere" @change="filterDettes(selectedExerciceId)"
                                        :items="optionsFichierSepa" item-text="label"
                                        item-value="key" label="Fichier SEPA"/>
                </v-col>

            </v-row>
            <v-row dense>
                <v-col cols="3" class="ml-5">
                    <ValidationProvider vid="selectedExercice" name="Exercice"
                                        rules="required"
                                        v-slot="{ errors }">
                        <v-exercices-list :value="selectedExerciceId"
                                            @input="filterDettes($event)"
                                            :errors="errors"
                                            optionAllExercices>
                        </v-exercices-list>
                    </ValidationProvider>
                </v-col>
                <v-col cols="3" class="ml-10">
                    <v-select v-model="selectedDetteReglee" @change="filterDettes(selectedExerciceId)"
                                        :items="optionsDetteReglee" item-text="label"
                                        item-value="key" label="Dette réglées"/>
                </v-col>
            </v-row>

        </template>
        <template v-slot:main="{ datatableSize }">
            <v-row justify="center" no-gutters>
                <v-col md="12" lg="12" xl="11">
                    <v-data-table v-model="selected"
                                  show-select
                                  :footer-props="footerProps"
                                  :headers="table.headers"
                                  :items="dettes"
                                  :loading="isLoading"
                                  fixed-header
                                  :height="datatableSize"
                                  class="elevation-1"
                                  no-data-text="Aucune dette."
                                  ref="liste_dettes"
                                  @update:pagination="updateSelected()"
                                  :options.sync="pagination"
                                  @input="onSelected()"
                                  :server-items-length="total_items">
                        <template v-slot:item="{ isSelected, select, item }">
                            <tr :active="isSelected && !item.is_reglee" @click="switchSelection(item)"
                                v-bind:class="{ custom_text_red_color: item.infos_bancaire_manquantes,
                                                custom_gray_line: item.is_reglee,
                                                custom_wheat_line: item.is_dette_anticipee }">
                                <td>
                                    <v-simple-checkbox :ripple="false" v-if="!item.is_dette_anticipee"
                                                       :value="isSelected && !item.is_dette_anticipee"
                                                       @input="select($event)"/>
                                </td>
                                <td class="text-lg-left">
                                    {{ item.associe_name }}
                                </td>
                                <td class="text-lg-left">
                                    {{ item.numero_tiers }}
                                </td>
                                <td class="text-lg-left">
                                    <v-row align="center">
                                        <v-col md="4" lg="11" xl="7" class="text-lg-left pl-0 pr-0">
                                            {{ item.type }}
                                        </v-col>
                                        <v-col cols="1" class="text-lg-right pa-0"
                                               v-if="item.message_information && item.message_information !== ''">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" small left>{{ "$vuetify.icons.info" }}</v-icon>
                                                </template>
                                                <span v-html="item.message_information"/>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </td>
                                <td class="text-lg-right">
                                    {{ item.montant_a_regler | roundEuroFilter }}
                                </td>
                                <td class="text-lg-right">
                                    {{ item.date_debut_dette | dateIsoToFrFilter }}
                                </td>

                                <td v-if="!item.is_dette_anticipee" class="text-lg-right">
                                    {{ item.date_limite_dette | dateIsoToFrFilter }}
                                </td>
                                <td v-else class="text-lg-center">
                                    -
                                </td>
                                <td v-if="item.date_dernier_reglement" class="text-lg-right">
                                    {{ item.date_dernier_reglement | dateIsoToFrFilter }}
                                </td>
                                <td v-else class="text-lg-right pl-5">
                                    -
                                </td>
                                <td class="text-lg-left" v-html="item.statut">
                                </td>
                                <td class="text-lg-center" v-if="!item.is_dette_anticipee && item.sepa_genere">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                {{ "$vuetify.icons.squareCheck" }}
                                            </v-icon>
                                        </template>
                                        <span v-html="'Fichier paiement SEPA déjà généré'"/>
                                    </v-tooltip>
                                </td>

                                <td class="text-lg-center"
                                    v-else-if="!item.is_dette_anticipee && item.is_eligible_sepa">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">
                                                {{ "$vuetify.icons.squareCheckWait" }}
                                            </v-icon>
                                        </template>
                                        <span v-html="'Paiement SEPA en attente'"/>
                                    </v-tooltip>
                                </td>
                                <td class="text-lg-center" v-else>
                                    -
                                </td>

                                <td v-if="!item.is_dette_anticipee" class="text-lg-right">
                                    {{ item.restant_du | roundEuroFilter }}
                                </td>
                                <td v-else class="text-lg-center">
                                    -
                                </td>

                                <td v-if="!item.is_dette_anticipee" class="text-lg-right">
                                    <v-icon-button-with-tool-tip :routeCible="{name: detailDetteRoute,
                                                                 params: {id: item.id}}"
                                                                 detail="Gérer les règlements de la dette"
                                                                 :icon="'$vuetify.icons.detail'"
                                                                 btnIsIcon btnRound btnText btnSmall iconSmall
                                                                 tooltipBottom
                                                                 btnColorClass="transparent"
                                                                 iconColorClass="grey darken-1"
                                                                 v-if="!item.is_dette_anticipee"/>
                                </td>
                                <td v-else class="text-lg-center">
                                    -
                                </td>
                            </tr>
                            <v-spacer/>
                        </template>
                        <template v-slot:[`body.append`]>
                            <tr class="v-data-table__nohoverezi">
                                <td colspan="3" ref=restantDuSelectedText class="text-lg-center font-weight-bold">
                                    Montant non réglé sélectionné
                                </td>
                                <td class="text-lg-right font-weight-bold">{{ restantDuSelected | roundEuroFilter }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="confirmationDialog" persistent max-width="600">
                        <v-card>
                            <v-card-title class="headline grey lighten-2" primary-title>Avertissement</v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row justify="center">
                                        Vous avez sélectionné des lignes. Cette sélection sera perdue si vous changez de page. Êtes-vous sûr de vouloir
                                        continuer ?
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-row justify="end" dense>
                                    <!-- Bouton modal -->
                                    <v-col md="4" lg="4" xl="4">
                                        <v-btn color="primary" @click="closePopUp" block>NON, ANNULER</v-btn>
                                    </v-col>
                                    <!-- Bouton modal -->
                                    <v-col md="4" lg="4" xl="4">
                                        <v-btn color="primary" @click="confirmChangePagination" block>OUI, CONFIRMER</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>
        <template slot="footer">
            <v-row justify="space-around" align="center" dense>
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn dark color="primary" outlined @click="downloadDettes" block>
                        <v-icon dark left>{{ "$vuetify.icons.excel" }}</v-icon>
                        Télécharger les dettes
                    </v-btn>
                </v-col>
                <v-spacer/>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <reglement-groupe
                                :selected="selected"
                                @submit="submit"
                                v-on="on">
                            </reglement-groupe>
                        </template>
                        <span v-html="messageInfoReglementsGroupesSepa"/>
                    </v-tooltip>
                </v-col>
                <!-- Petit bouton -->
                <v-col md="3" lg="3" xl="2">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">

                            <v-btn color="primary" v-on="on" @click="generateSepa" :disabled="!isSepaEnabledForCurrentSelection()"
                                   block>
                                Générer un fichier SEPA
                            </v-btn>
                        </template>
                        <span v-html="messageInfoBoutonsSepa"/>
                    </v-tooltip>
                </v-col>
                <v-col cols="1">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                        </template>
                        <span v-html="'Les paiements SEPA sont exclusivement accessibles pour les dettes réglées'"/>
                    </v-tooltip>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>

import internalApi from "@/api/internalApi";
import Utils from "@/utils";
import XLSX from "xlsx";
import loadingScreen from "@/components/LoadingScreen.vue";
import ReglementGroupe from "@/components/ReglementGroupe.vue";
import constantes from "@/utils/constantes";
import VIconButtonWithToolTip from "@/components/VIconButtonWithToolTip.vue";
import VExercicesList from "@/components/VExercicesList";
import _ from "lodash";
import authService from "@/services/authService";
import BaseLayout from "@/components/BaseLayout";

export default {
    name: "dettes-list",
    components: {
        BaseLayout,
        VExercicesList,
        loadingScreen,
        ReglementGroupe,
        VIconButtonWithToolTip
    },
    props: {
        univers: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            confirmationDialog: false,
            detailDetteRoute: "detail-dette",
            selectedTypeDette: "all",
            selectedDetteReglee: "all",
            selectedSepaGenere: "all",
            table: {
                headers: [
                    {text: "Tiers", sortable: false, value: "associe_name", align: "start"},
                    {text: "Numéro de tiers", sortable: false, value: "numero_tiers", align: "start"},
                    {text: "Type", sortable: false, value: "type", align: "start"},
                    {text: "Montant à régler", sortable: false, value: "montant_a_regler", align: "end"},
                    {text: "Date de la dette", sortable: false, value: "date_debut_dette", align: "end"},
                    {text: "Limite de remboursement", sortable: false, value: "date_limite_dette", align: "end"},
                    {text: "Date de dernier règlement", sortable: false, value: "date_dernier_reglement", align: "end"},
                    {text: "Statut", sortable: false, value: "statut", align: "start"},
                    {text: "SEPA", sortable: false, value: "eligible_sepa", align: "center", width: "80px"},
                    {text: "Restant dû", sortable: false, value: "restant_du", align: "end"},
                    {text: "Actions", sortable: false, value: "actions", align: "end"}
                ]
            },
            dettes: [],
            search: undefined,
            debouncedSearch: undefined,
            isLoading: true,
            loadingMessage: undefined,
            selected: [],
            selectedExerciceId: -1,
            exercicesList: [],
            dialog: undefined,
            informations_reglements: {
                date_de_reglement: undefined,
                detail_reglement: undefined,
                type_reglement: undefined,
                commentaire: undefined
            },
            type_reglement_enum: [
                {text: 'Virement', value: 'virement'},
                {text: 'Chèque', value: 'cheque'},
                {text: 'Autre (sans référence)', value: 'autre'},
            ],
            total_items: 0,
            nextPagination: {},
            pagination: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['statut'],
                sortDesc: [false],
            },
            switchTypeDette: this.getSwitchTypeDette(),
            optionsDetteReglee: [
                {
                    label: "Toutes les dettes",
                    key: "all",
                },
                {
                    label: "Réglées",
                    key: "reglee",
                },
                {
                    label: "Non réglées",
                    key: "non_reglee",
                },
            ],
            optionsFichierSepa: [
                {
                    label: "Toutes les dettes",
                    key: "all",
                },
                {
                    label: "Générés",
                    key: "genere",
                },
                {
                    label: "Non générés",
                    key: "non_genere",
                },
            ],
            messageInfoBoutonsSepa: "Ce bouton est actif uniquement si au moins une dette au statut 'Réglée' est sélectionnée et aucune dette au statut 'Non réglée' n'est sélectionnée.",
            messageInfoReglementsGroupesSepa: "Ce bouton est actif uniquement si au moins une dette au statut 'Non réglée' est sélectionnée et aucune dette au statut 'Réglée' n'est sélectionnée.",
            footerProps: {
                itemsPerPageOptions: [
                    10,
                    20,
                    50,
                    100,
                    200,
                    500,
                    1000,
                ],
            },
            company: undefined,
        };
    },
    mounted() {

        this.getCompany();

        this.search = undefined;
        if (this.univers === constantes.univers.capital) {
            this.detailDetteRoute = "cap-detail-dette";
        } else if (this.univers === constantes.univers.participation) {
            this.detailDetteRoute = "pb-detail-dette";
        } else if (this.univers === constantes.univers.interessement) {
            this.detailDetteRoute = "inte-detail-dette";
        }
        const filtres_actuels = this.$store.getters.getFiltresDettes();
        const currentPagination = this.$store.getters.getPaginationDettes();
        const currentCapPagination = this.$store.getters.getPaginationCapDettes();
        const currentPbPagination = this.$store.getters.getPaginationPbDettes();
        const currentIntePagination = this.$store.getters.getPaginationInteDettes();
        if (!_.isNil(filtres_actuels)) {
            if (filtres_actuels.exercice_filtre) {
                this.selectedExerciceId = filtres_actuels.exercice_filtre;
            }
            if (filtres_actuels.type_dette_filtre) {
                this.selectedTypeDette = filtres_actuels.type_dette_filtre;
                // Quand on change d'univers, on ne peut pas toujours transférer le filtre
                let check_key_exists = this.switchTypeDette.find(obj => {
                    return obj.key === this.selectedTypeDette
                });
                // Si l'ancien filtre n'est pas compatible, on affiche toutes les dettes
                if (check_key_exists === undefined) {
                    this.selectedTypeDette = "all";
                }
            }
            if (filtres_actuels.search) {
                this.search = filtres_actuels.search;
            }
            if (!_.isNil(currentCapPagination) && this.univers === constantes.univers.capital) {
                this.pagination = currentCapPagination;
            } else if (!_.isNil(currentPbPagination) && this.univers === constantes.univers.participation) {
                this.pagination = currentPbPagination;
            } else if (!_.isNil(currentIntePagination) && this.univers === constantes.univers.interessement) {
                this.pagination = currentIntePagination;
            } else if (!_.isNil(currentPagination)) {
                this.pagination = currentPagination; 
            }
            if (filtres_actuels.dette_sepa_genere_filter) {
                this.selectedSepaGenere = filtres_actuels.dette_sepa_genere_filter;
            }
        }
        const delayInMillis = 1000;
        this.debouncedSearch = _.debounce(this.fetchDettes, delayInMillis);
    },
    watch: {
        pagination: {
            handler(newV, oldV) {
                if (!this.confirmationDialog) {
                    if (this.selected.length !== 0) {
                        this.confirmationDialog = true;

                        // On enregistre la pagination qui sera utilisée si l'utilisateur confirme
                        // le changement
                        this.nextPagination.sortBy = newV.sortBy;
                        this.nextPagination.sortDesc = newV.sortDesc;
                        this.nextPagination.page = newV.page;
                        this.nextPagination.itemsPerPage = newV.itemsPerPage;

                        // Cette assignation trigger une nouvelle fois le watch. Une condition a été
                        // rajoutée pour ne pas repasser par ici : if (!this.confirmationDialog)
                        this.pagination.sortBy = oldV.sortBy;
                        this.pagination.sortDesc = oldV.sortDesc;
                        this.pagination.page = oldV.page;
                        this.pagination.itemsPerPage = oldV.itemsPerPage;
                    }
                    else {
                        this.fetchDettes();
                        if (this.univers === constantes.univers.capital) {
                            this.storePaginationCapDettes();
                        } else if (this.univers === constantes.univers.participation) {
                            this.storePaginationPbDettes();
                        } else if (this.univers === constantes.univers.interessement) {
                            this.storePaginationInteDettes();
                        } else {
                            this.storePaginationDettes();
                        }
                    }
                }
            },
            deep: true
        }
    },
    computed: {
        restantDuSelected() {
            const selected_rows = this.selected;
            const restant_du_array = selected_rows.map(x => x.restant_du);
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            return restant_du_array.reduce(reducer, 0).toFixed(2);
        },
    },

    methods: {
        getCompany() {
            return internalApi.company.getVersionCompany()
                .then(result => {
                    this.company = result;
                });
        },
        resetPagination() {
            this.pagination.page = 1;
        },
        isSepaEnabledForCurrentSelection() {

            return (!_.isEmpty(this.selected) && this.selected.every(val => val.is_reglee) && this.selected.some(val => val.is_eligible_sepa));
        },
        generateSepa() {
            this.resetColors();
            const body = {
                dettes_a_generer: _.flatMap(this.selected, function (item) {
                    return item.id
                }),
                company_id: this.company.id,
            };
            this.$refs.loadingComponent.setLoading(constantes.loading_message.telechargement);
            internalApi.dettes.genererSepa(body).then(result => {
                Utils.downloadFile(result, "sepa.xml", "text/xml");
                this.selected.forEach(item => item.sepa_genere = true);
                this.$refs.loadingComponent.resetLoading();
            }).catch(err => {
                console.error(err);
                this.$refs.loadingComponent.resetLoading();
                if (err.response.data.error_code === "E1600") {
                    let dettes_incompletes = err.response.data.detail.infos_bancaire_manquantes;
                    this.switchSelectedItemColor(dettes_incompletes);
                }
            });

        },
        switchSelectedItemColor(dettes_to_switch) {
            const intersection = _.filter(this.dettes, dette => dettes_to_switch.includes(dette.id));
            _.forEach(intersection, dette => this.$set(dette, 'infos_bancaire_manquantes', true));
        },
        resetColors() {
            _.forEach(this.dettes, dette => this.$delete(dette, 'infos_bancaire_manquantes', false));
        },
        switchSelection(item) {
            item.selected = !item.selected;
        },
        onSearchChange() {
            this.resetPagination();
            this.debouncedSearch();
        },
        hasCapitalAccess: function () {
                return authService.hasAccessCapital();
            },
        hasParticipationAccess: function () {
            return authService.hasAccessParticipation();
        },
        hasInteressementAccess: function () {
            return authService.hasAccessInteressement();
        },
        getSwitchTypeDette() {
            let types_dettes = [
                {
                    label: "Toutes les dettes",
                    key: "all",
                }
            ];
            if ([null, constantes.univers.capital].includes(this.univers) && this.hasCapitalAccess()){
                types_dettes.push(
                    {
                        label: "Annulations de parts sociales",
                        key: "annulations"
                    },
                    {
                        label: "Versement des intérêts aux parts sociales",
                        key: "versement_cap"
                    },
                );
            }
            if ([null, constantes.univers.participation].includes(this.univers) && this.hasParticipationAccess()){
               types_dettes.push(
                   {
                       label: "Déblocages",
                        key: "deblocage"
                    },
                    {
                        label: "Intérêts versés",
                        key: "versement_interets"
                    },
                    {
                        label: "Transferts externes de la participation",
                        key: "transfert_externe_pb"
                    },
                    {
                        label: "Versements de la participation",
                        key: "versement_pb"
                    },
                ); 
            }
            if ([null, constantes.univers.interessement].includes(this.univers) && this.hasInteressementAccess()){
               types_dettes.push(
                    {
                        label: "Transferts externes de l'intéressement",
                        key: "transfert_externe_inte"
                    },
                    {
                        label: "Versements de l'intéressement",
                        key: "versement_inte"
                    },
                ); 
            }
            return types_dettes;
        },
        downloadDettes() {
            this.$refs.loadingComponent.setLoading(constantes.loading_message.export);
            internalApi.dettes.downloadDettes({univers: this.univers})
            .then(response => {
                Utils.downloadFile(response, "dettes_" + Utils.formatUnivers(this.univers, "tout_univers") + ".xlsx", Utils.excelMimetype);
            }).catch(error => {
                console.error(error);
            })
            .finally(() => {
                this.$refs.loadingComponent.resetLoading();
            });
            
        },
        fetchDettes() {
            this.isLoading = true;
            internalApi.dettes.getAll({
                exercice_filter: this.selectedExerciceId,
                univers: this.univers,
                type_dette_filter: this.selectedTypeDette,
                dette_reglee_filter: this.selectedDetteReglee,
                dette_sepa_genere_filter: this.selectedSepaGenere,
                page: this.pagination.page,
                rows_per_page: this.pagination.itemsPerPage,
                search: this.search
            }).then(result => {
                this.dettes = result.dettes;
                this.selected = [];
                this.total_items = result.total_items;
            }).catch(err => {
                console.error(err);
            }).finally(() => {
                this.isLoading = false;
            });
        },
        storePaginationDettes() {
            this.$store.commit(
                "SET_PAGINATION_DETTES",
                _.cloneDeep(this.pagination)
            );
        },
        storePaginationCapDettes() {
            this.$store.commit(
                "SET_PAGINATION_CAP_DETTES",
                _.cloneDeep(this.pagination)
            );
        },
        storePaginationPbDettes() {
            this.$store.commit(
                "SET_PAGINATION_PB_DETTES",
                _.cloneDeep(this.pagination)
            );
        },
        storePaginationInteDettes() {
            this.$store.commit(
                "SET_PAGINATION_INTE_DETTES",
                _.cloneDeep(this.pagination)
            );
        },
        filterDettes(newSelectedExerciceId) {
            this.selectedExerciceId = newSelectedExerciceId;
            this.$store.commit("SET_FILTRES_DETTES", {
                exercice_filtre: this.selectedExerciceId,
                type_dette_filtre: this.selectedTypeDette,
                search: this.search,
                dette_sepa_genere_filter: this.selectedSepaGenere,
            });

            this.selected = [];
            this.resetPagination();
            this.fetchDettes();
        },
        submit() {
            this.filterDettes(this.selectedExerciceId);
            this.selected = [];
        },
        updateSelected() {
            this.selected = [];
        },
        onSelected() {
            this.selected = this.selected.filter(dette => !dette.is_dette_anticipee)
        },
        /**
         * Ferme la pop up
         */
        closePopUp() {
            this.confirmationDialog = false;
        },
        /**
         * Ferme la pop up et récupère la ventilation paginée à partir de la nouvelle pagination.
         */
        confirmChangePagination() {
            this.pagination.sortBy = this.nextPagination.sortBy;
            this.pagination.sortDesc = this.nextPagination.sortDesc;
            this.pagination.page = this.nextPagination.page;
            this.pagination.itemsPerPage = this.nextPagination.itemsPerPage;

            this.closePopUp();
            this.selected = [];
            this.fetchDettes();
        },
    }
};
</script>

