<!-- VUETIFY2 - OK -->
<template>
    <ValidationObserver v-slot="{ validate, errors, reset }">
        <base-layout :withFooter="false" headerSize="140px">
            <template v-slot:header>
                <h1>Exercice</h1>
                <v-tabs v-model="currentTab">
                    <v-tab key="donnees_exercice">Données exercice</v-tab>
                    <v-tab key="periodes_travail" :disabled="creationMode">Périodes de travail</v-tab>
                </v-tabs>
            </template>
            <template v-slot:main>
                <loading-screen ref="loadingComponent"/>
                <v-tabs-items v-model="currentTab">
                    <v-tab-item key="donnees_exercice">
                        <base-layout :withHeader="false">
                            <template v-slot:main>
                                <v-container>
                                    <common-exercice :date_debut="exercice.date_debut"
                                                     :date_fin="exercice.date_fin"
                                                     :date_ag_annuelle="exercice.date_ag_annuelle"
                                                     :libelle="exercice.libelle"
                                                     :benefices="exercice.benefices"
                                                     :montant_participation_brute="exercice.participation_brute"
                                                     :showExcedentsNetsPb="false"
                                                     @update-date-debut="exercice.date_debut = $event"
                                                     @update-date-fin="exercice.date_fin = $event"
                                                     @update-date-ag-annuelle="exercice.date_ag_annuelle = $event"
                                                     @update-libelle="exercice.libelle = $event"
                                                     @update-montant-participation-brute="exercice.participation_brute = $event"
                                                     @update-benefices="exercice.benefices = $event"
                                                     :exerciceValide="isRepartitionValide || exercice.is_common_exercice_any_state_valide"
                                    />
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <ValidationProvider vid="montant_interessement"
                                                                    name="Intéressement brut"
                                                                    rules="required|currency|min_value:0"
                                                                    v-slot="{ errors }">
                                                    <v-formatted-number-text-field
                                                        v-model="exercice.inte_exercice.montant_interessement"
                                                        prefixValue="€"
                                                        labelValue="Intéressement brut"
                                                        :reverseValue="true"
                                                        :errors="errors"
                                                        :disabledValue="isRepartitionValide"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-formatted-number-text-field 
                                                    v-model="tauxExcedentsNetsPourInte"
                                                    prefixValue="%"
                                                    labelValue="Taux d'excédents nets pour l'intéressement"
                                                    :reverseValue="true"
                                                    :disabledValue="true"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Contrôles plafonds</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle salarial</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Intéressement collectif plafond : 20% des salaires bruts`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row align="center" no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="masse_salariale"
                                                                            name="Salaires bruts"
                                                                            rules="required|currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.masse_salariale"
                                                                prefixValue="€"
                                                                labelValue="Salaires bruts"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || !canEditMasseSalariale"/>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Intéressement collectif plafond&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([plafond_interessement_collectif])">{{plafond_interessement_collectif | roundEuroFilter(0)}}</span>
                                                        <span v-else class="orange--text">Renseigner les salaires bruts pour activer.</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-row align="center" no-gutters>
                                                    <v-col class="shrink text-no-wrap">
                                                        <h4>Contrôle PASS</h4>
                                                    </v-col>
                                                    <v-col md="1" lg="1" xl="1" class="pl-2">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}
                                                                </v-icon>
                                                            </template>
                                                            <span
                                                                v-html="`Intéressement individuel plafond : ¾ × Plafond Annuel de la Sécurité Sociale`"/>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row align="center" no-gutters>
                                                    <v-col>
                                                        <ValidationProvider vid="plafond_annuel_securite_sociale"
                                                                            name="Plafond Annuel de la Sécurité Sociale"
                                                                            ref="plafond_annuel_securite_sociale"
                                                                            rules="required|currency|min_value:0"
                                                                            v-slot="{ errors }">
                                                            <v-formatted-number-text-field
                                                                v-model="exercice.plafond_annuel_securite_sociale"
                                                                prefixValue="€"
                                                                labelValue="Plafond Annuel de la Sécurité Sociale"
                                                                :reverseValue="true"
                                                                :errors="errors"
                                                                :disabledValue="isRepartitionValide || !canEditPass"
                                                            />
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        Intéressement individuel plafond&nbsp;:
                                                        <span class="text-no-wrap" v-if="areCorrect([plafond_interessement_individuel])">{{plafond_interessement_individuel | roundEuroFilter(0)}}</span>
                                                        <span v-else class="orange--text">Renseigner le Plafond Annuel de la Sécurité Sociale pour activer.</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Placement de l'intéressement</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" align="center" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="hasSupportDeversement"
                                                            label="Support de déversement par défaut"
                                                            @change="switchHasSupportDeversement"
                                                            :disabled="isAffectationValide"/>
                                            </v-col>
                                            <v-col md="5" lg="5" xl="5">
                                                <ValidationProvider vid="inte_support_deversement_id"
                                                                    name="Support"
                                                                    :rules="{ required: hasSupportDeversement }"
                                                                    v-slot="{ errors }">
                                                    <v-inte-supports-list v-model="exercice.inte_exercice.inte_support_deversement_id"
                                                                     label="Support"
                                                                     :supportFilter="'actif'"
                                                                     :errors="errors"
                                                                     :isEnabled="hasSupportDeversement && !isAffectationValide"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <h3 slot="header">Affectation de l'intéressement</h3>
                                    <v-container pt-0>
                                        <v-row justify="space-between" align="center" dense>
                                            <v-col md="5" lg="5" xl="5">
                                                <v-checkbox v-model="affectation_inte"
                                                            value="allows_edit_versement_immediat"
                                                            label="Versement immédiat"
                                                            :disabled="isAffectationValide"
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small left v-on="on"> {{ canEditVersementImmediat ? "$vuetify.icons.droitsAccesDeverrouille" : "$vuetify.icons.droitsAccesVerrouille"}}</v-icon>
                                                    </template>
                                                    <span 
                                                        v-html="getTooltipTextIconeCadenas(canEditVersementImmediat)"/>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-container>
                            </template>
                            <template v-slot:footer>
                                <v-row no-gutters>
                                    <v-spacer/>
                                    <!-- Petits boutons -->
                                    <v-col v-if="!isAffectationValide" md="6" lg="6" xl="4">
                                        <v-save-buton-with-validation :validateForm="validate"
                                                                      :resetForm="reset"
                                                                      :errorsList="errors"
                                                                      @emit-cancel="returnToExerciceList"
                                                                      @emit-valid-save="save"/>
                                    </v-col>
                                    <!-- Petit bouton -->
                                    <v-col v-else md="3" lg="3" xl="2">
                                        <v-btn block @click="returnToExerciceList" color="primary">Retour</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-layout>
                    </v-tab-item>
                    <v-tab-item key="periodes_travail">
                        <inte-donnees-beneficiaires :exercice="exercice"
                                                    :disabled="isRepartitionValide"
                                                    :showSalairesPresences="showSalairesPresences"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </base-layout>
    </ValidationObserver>
</template>

<script>
    import authService from "@/services/authService";
    import VFormattedNumberTextField from "@/components/VFormattedNumberTextField.vue";
    import internalApi from "@/api/internalApi";
    import VSaveButonWithValidation from "@/components/VSaveButonWithValidation.vue";
    import loadingScreen from "@/components/LoadingScreen.vue";
    import constantes from "@/utils/constantes";
    import BaseLayout from "@/components/BaseLayout";
    import CommonExercice from "@/components/exercice/CommonExercice";
    import VInteSupportsList from "@/components/supportsList/VInteSupportsList";
    import moment from "moment";
    import InteDonneesBeneficiaires from "@/views/common/exercice/InteDonneesBeneficiaires";
    import rolesList from "@/router/rolesList";
    import Utils from "@/utils";
    import _ from "lodash";

    const AFFECTATION_INTE_FIELDS = [
        "allows_edit_versement_immediat",
    ];

    /**
     * Composant affichant les informations liées à un exercice côté participation
     * @displayName Participation - InteExercice
     */
    export default {
        components: {
            InteDonneesBeneficiaires,
            VFormattedNumberTextField,
            VSaveButonWithValidation,
            loadingScreen,
            BaseLayout,
            CommonExercice,
            VInteSupportsList,
        },
        props: {
            /**
             * Détermine si on est en train de créer un nouvel exercice ou de modifier
             * un existant
             */
            creationMode: undefined
        },
        data() {
            return {
                currentTab: undefined,
                exercice: {
                    date_debut: undefined,
                    date_fin: undefined,
                    date_ag_annuelle: undefined,
                    libelle: undefined,
                    benefices: undefined,
                    participation_brute: undefined,
                    masse_salariale: undefined,
                    plafond_annuel_securite_sociale: undefined,
                    is_common_exercice_any_state_valide: true,
                    inte_exercice: {
                        id: undefined,
                        montant_interessement: undefined,
                        is_repartition_inte_valide: true,
                        is_affectation_inte_valide: true,
                        inte_support_deversement_id: undefined,
                        allows_edit_versement_immediat: true,
                    },
                },
                company: undefined,
                isRepartitionValide: true,
                canEditMasseSalariale: false,
                canEditPass: false,
                isAffectationValide: true,
                showSalairesPresences: false,
                hasSupportDeversement: false,
                affectation_inte: [],

                plafond_interessement_collectif: null,
                plafond_interessement_individuel: null,

                debouncedComputePlafondInteressementCollectif: undefined,
                debouncedComputePlafondInteressementIndividuel: undefined,
                droitAccesSubText: `sur l'espace personnel. <br/> Ce paramètre peut être modifié depuis les droits d'accès.`,
                canEditVersementImmediat: false,
            };
        },
        mounted() {

            this.$refs.loadingComponent.setLoading(constantes.loading_message.chargement);
            let currentRoles = authService.getCurrentRoles();
            this.showSalairesPresences = currentRoles.includes(rolesList.RESPONSABLE)
            let promises = [];

            const delayInMillis = 500;
            this.debouncedComputePlafondInteressementCollectif = _.debounce(this.computePlafondInteressementCollectif, delayInMillis);
            this.debouncedComputePlafondInteressementIndividuel = _.debounce(this.computePlafondInteressementIndividuel, delayInMillis);

            let promise_company = this.getCompany();
            promises.push(promise_company);

            if (!this.creationMode) {
                const {exerciceId} = this.$route.params;
                let promise_exercice = this.getInteExerciceById(exerciceId).then(result => {
                    this.isRepartitionValide = this.exercice.inte_exercice.is_repartition_inte_valide;
                    this.canEditMasseSalariale = this.exercice.can_edit_masse_salariale;
                    this.canEditPass = this.exercice.can_edit_pass;
                    this.isAffectationValide = this.exercice.inte_exercice.is_affectation_inte_valide;
                    this.hasSupportDeversement = this.exercice.inte_exercice.inte_support_deversement_id !== null
                });
                promises.push(promise_exercice);
            } else {
                this.exercice = {
                    date_debut: undefined,
                    date_fin: undefined,
                    date_ag_annuelle: undefined,
                    libelle: undefined,
                    benefices: undefined,
                    participation_brute: undefined,
                    masse_salariale: undefined,
                    plafond_annuel_securite_sociale: undefined,
                    inte_exercice: {
                        id: undefined,
                        montant_interessement: undefined,
                        inte_support_deversement_id: undefined,
                        allows_edit_versement_immediat: true,
                    },
                }
                this.isRepartitionValide = false;
                this.canEditMasseSalariale = true;
                this.canEditPass = true;
                this.isAffectationValide = false;
            }

            Promise.all(promises).finally(() => {
                this.initCheckboxListAffectationInte();
                this.$refs.loadingComponent.resetLoading();
            });
            this.canEditVersementImmediat = this.$store.getters.hasDroitAcces(
                constantes.droits_acces.noms.espace_inte_affectation_versement_immediat,
                [constantes.droits_acces.valeurs_possibles.edition],
            )

        },
        computed: {
            hasAccessCapital() {
                return authService.hasAccessCapital();
            },
            hasAccessParticipation() {
                return authService.hasAccessParticipation();
            },
            localMontantInteressement: {
                get: function () {
                    return this.getValueIfNotNil(this.exercice.inte_exercice.montant_interessement);
                },
            },
            tauxExcedentsNetsPourInte() {
                if (this.localMontantInteressement == 0 || this.localMontantInteressement == "") {
                    return "";
                }
                let res = Utils.formatNumber(this.localMontantInteressement / this.exercice.benefices * 100 );
                if (isNaN(res)) {
                    return "";
                }
                return res;
            },
        },
        watch: {
            "exercice.masse_salariale": function () {
                this.debouncedComputePlafondInteressementCollectif();
            },
            "exercice.plafond_annuel_securite_sociale": function () {
                this.debouncedComputePlafondInteressementIndividuel();
            },
        },
        methods: {
            /**
             * Méthode pour empecher une erreur de validation à l'ouverture de la page
             * En effet comme on passe par une valeur computed, vee-validate considère que le champ à été modifié
             * et passe donc la validation. Si le champ est null ou undefined, on le force à ""
             * @param initialValue
             * @return {string}
             */
            getValueIfNotNil(initialValue) {
                let returnValue = "";
                if (!_.isNil(initialValue)) {
                    returnValue = initialValue;
                }
                return returnValue;
            },
            getInteExerciceById(inte_exercice_id) {
                return internalApi.inteExercice.getById(inte_exercice_id).then(
                    result => {
                        this.exercice = result;
                    }
                );
            },
            getCompany() {
                return internalApi.company.getVersionCompany()
                    .then(result => {
                        this.company = result;
                    });
            },
            /**
             * Vérifie que chaque entrée d'une liste est non vide et est un nombre
             * @param fields la liste des champs à vérifier
             * @returns {boolean}
             */
            areCorrect(fields = []) {
                let areCorrect = true;
                fields.forEach(function (element) {
                    if (_.isNil(element) || element === "" || isNaN(element)) {
                        areCorrect = false;
                    }
                });
                return areCorrect;
            },
            isFilled(field) {
                return !_.isNil(field) && field !== ""
            },
            /**
             * Selon si on est en création ou édition d'un exercice, enregistre le nouvel exercice
             * ou les modifications de l'exercice. Emet selon le cas un évènement create ou update
             */
            save() {
                 // If checkbox for support_deversement not checked, send null -> None server side
                if (!this.hasSupportDeversement){
                    this.exercice.inte_exercice.inte_support_deversement_id = null;
                }
                this.exercice.company_id = this.company.id;
                this.$refs.loadingComponent.setLoading(constantes.loading_message.enregistrement);

                this.setCheckboxListAffectationInte();

                if (this.creationMode) {
                    internalApi.inteExercice
                        .postAllWithToastConfirmation(this.exercice)
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            this.$refs.loadingComponent.resetLoading();
                            console.error(err);
                        });
                } else {
                    const data_to_send = _.cloneDeep(this.exercice);
                    // On ne veut pas pouvoir mettre ces données à jour depuis cet écran
                    delete data_to_send.id;
                    delete data_to_send.inte_exercice.id;
                    delete data_to_send.cap_exercice;
                    delete data_to_send.pb_exercice;
                    delete data_to_send.abo_exercice;
                    internalApi.inteExercice
                        .putByIdWithToastConfirmation(
                            this.exercice.id,
                            data_to_send
                        )
                        .then(response => {
                            this.$refs.loadingComponent.resetLoading();
                            this.returnToExerciceList();
                        })
                        .catch(err => {
                            this.$refs.loadingComponent.resetLoading();
                            console.error(err);
                        });
                }
            },
            returnToExerciceList() {
                this.$router.push({
                    name: "inte-exercices"
                });
            },
            switchHasSupportDeversement() {
                if (!this.hasSupportDeversement) {
                    this.exercice.inte_exercice.inte_support_deversement_id = null;
                }
            },
            initCheckboxListAffectationInte() {
                this.affectation_inte = Utils.fromObjectToCheckboxList(
                    this.exercice.inte_exercice, AFFECTATION_INTE_FIELDS
                );
            },
            setCheckboxListAffectationInte() {
                Utils.fromCheckboxListToObject(
                    this.affectation_inte, this.exercice.inte_exercice, AFFECTATION_INTE_FIELDS
                );
            },

            // Calculs des contraintes fonctionnelles (planchers/plafonds)

            computePlafondInteressementCollectif() {
                let params = {
                    "salaires_brut": this.exercice.masse_salariale,
                };
                return internalApi.inteExercice.computePlafondInteressementCollectif(params).then(
                    res => {
                        this.plafond_interessement_collectif = res.result;
                    }
                );
            },
            computePlafondInteressementIndividuel() {
                let params = {
                    "pass_value": this.exercice.plafond_annuel_securite_sociale,
                };
                return internalApi.inteExercice.computePlafondInteressementIndividuel(params).then(
                    res => {
                        this.plafond_interessement_individuel = res.result;
                    }
                );
            },
            getTooltipTextIconeCadenas(estDeverrouille) {
                const estDeverrouilleStr = estDeverrouille ? "déverrouillée" : "verrouillée";
                return `L'option d'affectation est ${estDeverrouilleStr} sur l'espace personnel. <br/> Ce paramètre peut être modifié depuis les droits d'accès.`;
            },

        }
    };
</script>
