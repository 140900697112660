<!-- VUETIFY2 - OK -->
<template>
    <v-card class="elevation-0">
        <v-card-title class="text-subtitle-1">
            Type "{{ typeName }}"
        </v-card-title>
        <v-expansion-panels multiple v-model="openedPanels">
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle-1" :expand-icon="'$vuetify.icons.chevronDown'">
                    Placeholders
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-chip-group>
                        <v-chip outlined color="primary" v-for="placeholder in typeSpecifications.placeholders" :key="placeholder.name">
                            {{ placeholder.name }}
                        </v-chip>
                    </v-chip-group>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle-1" :expand-icon="'$vuetify.icons.chevronDown'">
                    Formats
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-chip-group v-if="typeSpecifications.formats.length > 0">
                        <v-chip outlined color="primary" v-for="format in typeSpecifications.formats" :key="format.name">
                            {{ format.name }}
                        </v-chip>
                    </v-chip-group>
                    <span v-else>Aucun</span>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>
<script>
export default {
    name: "ChipsLegend",
    props: {
        typeName: {
            type: String,
            required: true,
        },
        typeSpecifications: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            openedPanels: [0],
        }
    },    
}
</script>
