var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',{attrs:{"withHeader":false,"datatableExcludedSizeDefault":"30vh"},scopedSlots:_vm._u([{key:"main",fn:function(ref){
var datatableSize = ref.datatableSize;
return [_c('loading-screen',{ref:"loadingComponent"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Tableau de support aux Prélèvement Forfaitaire Unique")]),_c('v-col',{staticClass:"ml-3",attrs:{"md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","left":""}},on),[_vm._v(_vm._s("$vuetify.icons.info"))])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s("La version des Cerfa retenue par l'outil est celle valable à la date du règlement le plus récent de l'exercice")}})])],1)],1),_c('br'),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[(_vm.univers === 'CAPITAL')?_c('span',[_vm._v(" Données concernant l'exercice clos le "+_vm._s(_vm._f("dateIsoToFrFilter")(_vm.dateFin))+" ")]):(_vm.univers === 'PARTICIPATION')?_c('span',[_vm._v(" Données concernant l'année "+_vm._s(_vm.year)+", mois de "+_vm._s(_vm.month ? _vm.getMonthFromNumber(_vm.month) : '(non choisi)')+" ")]):_vm._e()]),_c('div',[_c('span',[_vm._v(" Les données affichées correspondent à la version du document '"+_vm._s(_vm.selectedCerfa ? _vm.selectedCerfa.label : '(non choisi)')+"' ")])])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-detail-societe',{attrs:{"dateVersionSociete":_vm.dateFin}})],1)],1),_c('br'),_c('br'),_c('div',{staticClass:"grey lighten-1 pa-1 mr-3"},[_c('h5',[_vm._v(_vm._s(_vm.titrePfuTableauPrelevementsForfaitaires))])]),_c('v-row',{staticClass:"mr-3",attrs:{"no-gutters":"","wrap":"","align":"center"}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12","wrap":""}},[_c('v-data-table',{ref:"pfu_datatable_T1",staticClass:"elevation-1",attrs:{"fixed-header":"","no-data-text":"Aucune donnée.","headers":_vm.headers,"items":_vm.detailPfuTableauPrelevementsForfaitaires,"hide-default-footer":"","max-height":datatableSize,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.libelle_taux",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.libelle_taux)}})]}},{key:"item.base_imposable",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item.base_imposable,0)))])]}},{key:"item.taux",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundPercentFilter")(item.taux)))])]}},{key:"item.impot",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item.impot,0)))])]}}],null,true)})],1)],1),_c('br'),_c('br'),_c('div',{staticClass:"grey lighten-1 pa-1 mr-3"},[_c('h5',[_vm._v(_vm._s(_vm.titrePfuTableauChargesInterets))])]),_c('v-row',{staticClass:"mr-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{ref:"pfu_datatable_T2",staticClass:"elevation-1",attrs:{"fixed-header":"","no-data-text":"Aucune donnée.","headers":_vm.headers,"items":_vm.detailPfuTableauChargesInterets,"hide-default-footer":"","max-height":datatableSize,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.libelle_taux",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.libelle_taux)}})]}},{key:"item.base_imposable",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item.base_imposable,0)))])]}},{key:"item.taux",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundPercentFilter")(item.taux)))])]}},{key:"item.impot",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("roundEuroFilter")(item.impot,0)))])]}}],null,true)})],1)],1),_c('br'),_c('div',{staticClass:"mr-3"},[_c('span',[_c('b',[_vm._v("Total : ")]),_vm._v(_vm._s(_vm._f("roundEuroFilter")(_vm.total,0)))])]),_c('br')]}}])},[_c('template',{slot:"footer"},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"md":"4","lg":"4","xl":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.downloadPfu2777PDF}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s("$vuetify.icons.pdf"))]),_vm._v(" Générer le CERFA 2777 ")],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }