<!-- VUETIFY2 - OK -->
<template>
    <base-layout :withHeader="false" datatableExcludedSizeDefault="30vh">
        <template v-slot:main="{ datatableSize }">
            <loading-screen ref="loadingComponent"/>
            <v-row no-gutters>
                <h2>Tableau de support aux Prélèvement Forfaitaire Unique</h2>
                <v-col md="1" class="ml-3">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon small left v-on="on">{{ "$vuetify.icons.info" }}</v-icon>
                        </template>
                        <span
                            v-html="`La version des Cerfa retenue par l'outil est celle valable à la date du règlement le plus récent de l'exercice`">
                        </span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <br/>
            <v-row no-gutters align="center" justify="space-around">
                <v-col cols="4">
                    <div>
                        <span  v-if="univers === 'CAPITAL'">
                            Données concernant l'exercice clos le {{ dateFin | dateIsoToFrFilter }}
                        </span>
                        <span v-else-if="univers === 'PARTICIPATION'">
                            Données concernant l'année {{ year }}, mois de {{ month ? getMonthFromNumber(month) : '(non choisi)' }}
                        </span>
                    </div>
                    <div>
                        <span>
                        Les données affichées correspondent à la version du document '{{ selectedCerfa ? selectedCerfa.label : '(non choisi)' }}'
                        </span>
                    </div>
                </v-col>
                <v-col cols="8">
                    <v-detail-societe :dateVersionSociete="dateFin"/>
                </v-col>
            </v-row>
            <br/>
            <br/>
            <div class="grey lighten-1 pa-1 mr-3">
                <h5>{{ titrePfuTableauPrelevementsForfaitaires }}</h5>
            </div>
            <v-row no-gutters wrap align="center" class="mr-3">
                <v-col md="12" lg="12" xl="12" wrap>
                    <v-data-table ref="pfu_datatable_T1"
                                  class="elevation-1"
                                  fixed-header
                                  no-data-text="Aucune donnée."
                                  :headers="headers"
                                  :items="detailPfuTableauPrelevementsForfaitaires"
                                  hide-default-footer
                                  :max-height="datatableSize"
                                  :loading="loading">
                        <template v-slot:[`item.libelle_taux`]="{ item }">
                            <div v-html="item.libelle_taux"></div>
                        </template>
                        <template v-slot:[`item.base_imposable`]="{ item }">
                            <div>{{ item.base_imposable | roundEuroFilter(0) }}</div>
                        </template>
                        <template v-slot:[`item.taux`]="{ item }">
                            <div>{{ item.taux | roundPercentFilter }}</div>
                        </template>
                        <template v-slot:[`item.impot`]="{ item }">
                            <div>{{ item.impot | roundEuroFilter(0) }}</div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <br/>
            <br/>
            <div class="grey lighten-1 pa-1 mr-3">
                <h5>{{ titrePfuTableauChargesInterets }}</h5>
            </div>
            <v-row no-gutters class="mr-3">
                <v-col md="12" lg="12" xl="12">
                    <v-data-table ref="pfu_datatable_T2"
                                  class="elevation-1"
                                  fixed-header
                                  no-data-text="Aucune donnée."
                                  :headers="headers"
                                  :items="detailPfuTableauChargesInterets"
                                  hide-default-footer
                                  :max-height="datatableSize"
                                  :loading="loading">
                        <template v-slot:[`item.libelle_taux`]="{ item }">
                            <div v-html="item.libelle_taux"></div>
                        </template>
                        <template v-slot:[`item.base_imposable`]="{ item }">
                            <div>{{ item.base_imposable | roundEuroFilter(0) }}</div>
                        </template>
                        <template v-slot:[`item.taux`]="{ item }">
                            <div>{{ item.taux | roundPercentFilter }}</div>
                        </template>
                        <template v-slot:[`item.impot`]="{ item }">
                            <div>{{ item.impot | roundEuroFilter(0) }}</div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <br/>
            <div class="mr-3">
                <span><b>Total : </b>{{ total | roundEuroFilter(0)}}</span>
            </div>
            <br/>
        </template>
        <template slot="footer">
            <v-row no-gutters justify="end">
                <!-- Grand bouton -->
                <v-col md="4" lg="4" xl="3">
                    <v-btn color="primary" @click="downloadPfu2777PDF" block>
                        <v-icon left>{{ "$vuetify.icons.pdf" }}</v-icon>
                        Générer le CERFA 2777
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </base-layout>
</template>

<script>
    import VDetailSociete from "@/components/VDetailSociete.vue"
    import loadingScreen from "@/components/LoadingScreen.vue";
    import BaseLayout from "@/components/BaseLayout";
    import constantes from "@/utils/constantes"
    import {TOAST} from "@/plugins/toast/definition";

    /**
     * Composant affichant les informations relatives au PFU
     * @display_name Commun - PFU
     */
    export default {
        name: "v-pfu",
        components: {
            BaseLayout,
            loadingScreen,
            VDetailSociete,
        },
        data() {
            return {
                detailPfuTableauPrelevementsForfaitaires: [],
                titrePfuTableauPrelevementsForfaitaires: "",
                detailPfuTableauChargesInterets: [],
                titrePfuTableauChargesInterets: "",
                dateFin: undefined,
                total: undefined,
                headers: [],
                exercice: undefined,
                loading: false,
            }
        },
        props: {
            /**
             * Indique l'univers sélectionné
             */
            univers: {
                required: true
            },
            /**
             * L'id de l'exercice à traiter
             */
            exerciceId: {
                required: true
            },
            /**
             * L'année sélectionnée (ex: 2020, 2029, ...)
             */
            year: {
                required: true
            },
            /**
             * Le mois sélectionné (1 pour Janvier, ..., 12 pour Décembre)
             */
            month: {
                required: true
            },
            /**
             * Le modèle de cerfa à utiliser
             */
            selectedCerfa: {
                type: Object
            }
        },
        mounted() {
            this.headers = this.computeHeaders();
        },
        methods: {
            computeHeaders(){
                //overriden
            },
            loadDataSync(){
                this.$refs.loadingComponent.setLoading("Récupération des données PFU en cours ...");
                let finalData;
                this.loadData().then(data => {
                    finalData = data;
                }).catch(rejected => {
                    finalData = rejected;
                }).finally(() => {
                    this.$refs.loadingComponent.resetLoading();
                    this.dateFin = finalData.dateFin;
                    this.total = finalData.total;
                    this.titrePfuTableauPrelevementsForfaitaires = finalData.titrePfuTableauPrelevementsForfaitaires;
                    this.detailPfuTableauPrelevementsForfaitaires = Object.values(finalData.detailPfuTableauPrelevementsForfaitaires);
                    this.titrePfuTableauChargesInterets = finalData.titrePfuTableauChargesInterets;
                    this.detailPfuTableauChargesInterets = Object.values(finalData.detailPfuTableauChargesInterets);
                });
            },
            loadData(){
                //overriden
            },
            /**
             * Vérifie qu'on a bien sélectionné un cerfa
             * @returns true si tout va bien false sinon
             */
            checkCanDownloadPfu: function() {
                if (this.selectedCerfa === undefined){
                    TOAST.error(constantes.toast_message.missingCerfaTitle, constantes.toast_message.missingCerfaBody);
                    return false;
                }
                return true;
            },
            downloadPfu2777PDF(){
                //overriden
            },
            getMonthFromNumber(number){
                return constantes.monthsList[number-1]
            },
        }
    }
</script>